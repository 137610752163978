import { ReactElement } from 'react';
import { useScrollTrigger } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function LinkedinButton(): ReactElement {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <IconButton
      component="a"
      href="https://www.linkedin.com/company/bodemdigitaalopdekaart/"
      rel="noreferrer"
      sx={{ color: trigger ? 'primary.main' : 'white' }}
      target="_blank"
    >
      <LinkedInIcon />
    </IconButton>
  );
}
