import { ReactElement, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollToTopProps {
  children: ReactNode;
}

export default function ScrollToTop({ children }: ScrollToTopProps): ReactElement {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
