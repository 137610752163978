import { Tooltip } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import NazcaSolutionsLogo from './NazcaSolutionsLogo';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

export interface AboutCompanyCardProps extends BoxProps {
  body: string;
  logo: typeof NazcaSolutionsLogo;
  name: string;
  url: string;
}
export function AboutCompanyCard({ body, logo, name, url, ...props }: AboutCompanyCardProps) {
  return (
    <Box className={clsx('wrapper', props.className)} {...props}>
      <Box
        className="root"
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          gap: 3,
          py: 4,
        }}
      >
        <Box
          className="logo-container"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            order: { md: 2, xs: 1 },
          }}
        >
          <Tooltip
            className="logo-tooltip"
            title={`Klik hier om naar de website van ${name} te gaan.`}
          >
            <Box
              className="logo"
              component={logo}
              onClick={() => {
                window.open(url);
              }}
              sx={{
                cursor: 'pointer',
                display: 'block',
                height: ({ typography }) => `calc(10 * ${typography.body1.fontSize})`,
                mb: { md: 0, xs: 5 },
                width: '62%',
              }}
            />
          </Tooltip>
        </Box>
        <Box
          className="text-container"
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 3,
            justifyContent: 'center',
            mt: { md: 0, xs: 3 },
            order: { md: 1, xs: 2 },
          }}
        >
          <Typography
            className="text-title"
            color="primary"
            sx={{
              fontStyle: 'italic',
            }}
            variant="h4"
          >
            Over {name}
          </Typography>
          <Typography className="text-body" color="primary" variant="h5">
            {body}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
