import { CheckboxWithLabel, RadioGroup, Select, TextField } from 'formik-mui';
import { Field, Form, FormikProps, useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';

import { FormValues } from '../formik';
import privacyStatement from '../../../assets/documents/20220714-BDOK Privacy Verklaring.pdf';

export default function BDOKAcademyForm({ isSubmitting }: FormikProps<FormValues>): ReactElement {
  const { typography } = useTheme();
  const ctx = useFormikContext<FormValues>();

  return (
    <Form style={{ display: 'flex', flexDirection: 'column' }}>
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Voornaam*"
        margin="dense"
        name="firstName"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Tussenvoegsel (optioneel)"
        margin="dense"
        name="insertion"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Achternaam*"
        margin="dense"
        name="lastName"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="E-mailadres*"
        margin="dense"
        name="emailAddress"
      />
      <Typography sx={{ mb: 3, mt: 5 }}>
        Geef onderstaand aan welke datum/welk tijdstip het beste past. Let op: kies maximaal 1
        datum, zodat we je op de lijst kunnen zetten voor deze datum. Als deze datum vol is, dan
        krijg je een melding. Kies dan een andere datum die past.
      </Typography>
      <Field
        component={Select}
        label="Datum & Tijd*"
        MenuProps={{ disableScrollLock: true }}
        name="dateTime"
      >
        {/* eslint-disable-next-line prettier/prettier */}
        <MenuItem value={`${new Date(2023, 2, 16, 11, 0).getTime()}-${new Date(2023, 2, 16, 12, 0).getTime()}`}>
          Donderdag 16-03-2023 11.00-12.00
        </MenuItem>

        {/* eslint-disable-next-line prettier/prettier */}
        <MenuItem value={`${new Date(2023, 5, 15, 11, 0).getTime()}-${new Date(2023, 5, 15, 12, 0).getTime()}`}>
          Donderdag 15-06-2023 11.00-12.00
        </MenuItem>

        {/* eslint-disable-next-line prettier/prettier */}
        <MenuItem value={`${new Date(2023, 8, 14, 11, 0).getTime()}-${new Date(2023, 8, 14, 12, 0).getTime()}`}>
          Donderdag 14-09-2023 11.00-12.00
        </MenuItem>

        {/* eslint-disable-next-line prettier/prettier */}
        <MenuItem value={`${new Date(2023, 11, 14, 11, 0).getTime()}-${new Date(2023, 11, 14, 12, 0).getTime()}`}>
          Donderdag 14-12-2023 11.00-12.00
        </MenuItem>

        {/* <MenuItem value={new Date(2022, 9, 6, 15, 0).getTime()}>
          Donderdag 6 oktober 2022 om 15.00 uur
        </MenuItem>
        <MenuItem value={new Date(2022, 11, 8, 15, 0).getTime()}>
          Donderdag 8 december 2022 om 15.00 uur
        </MenuItem> */}
      </Field>
      <Typography gutterBottom sx={{ mt: 5 }}>
        Wat is je kennisniveau?*
      </Typography>
      <Field component={RadioGroup} name="proficiency" style={{ flexDirection: 'row' }}>
        <FormControlLabel
          control={<Radio disabled={isSubmitting} />}
          disabled={isSubmitting}
          label="Beginner"
          value="Beginner"
        />
        <FormControlLabel
          control={<Radio disabled={isSubmitting} />}
          disabled={isSubmitting}
          label="Ervaren"
          value="Ervaren"
        />
      </Field>

      <Box sx={{ mt: 4 }} />

      <Field
        component={CheckboxWithLabel}
        // error={ctx.touched.agree && Boolean(ctx.errors.agree)}
        // helperText={ctx.touched.agree && ctx.errors.agree}
        Label={{
          label: (
            <Typography
              sx={{
                color: ({ palette }) =>
                  !ctx.values.agree && ctx.errors.agree && ctx.touched.agree
                    ? palette.error.main
                    : undefined,
              }}
            >
              {!ctx.values.agree && ctx.errors.agree && ctx.touched.agree
                ? 'U dient akkoord te gaan met de inschrijving en het bedrag van € 33,-'
                : 'Ik ga akkoord met de inschrijving en het bedrag van € 33,-'}
            </Typography>
          ),
        }}
        name="agree"
        sx={{
          color: ({ palette }: Theme) =>
            !ctx.values.agree && ctx.errors.agree && ctx.touched.agree
              ? palette.error.main
              : undefined,
        }}
        type="checkbox"
      />
      <Typography gutterBottom sx={{ my: 3 }} textAlign="end" variant="caption">
        Velden met een * zijn verplicht. Benieuwd hoe wij met jouw gegevens omgaan? Dit lees je in
        onze{' '}
        <Box component="a" href={privacyStatement} rel="noopener noreferrer" target="_blank">
          privacyverklaring
        </Box>
        .
      </Typography>
      <Button
        disabled={isSubmitting}
        size="large"
        sx={{ borderRadius: '21.125px', ml: 'auto', mt: ({ spacing }) => spacing(2) }}
        type="submit"
        variant="contained"
      >
        Aanmelden
      </Button>
    </Form>
  );
}
