import { NewsArticleOne } from './news-article-one';
import { NewsArticleTwo } from './news-article-two';
import { ReactNode } from 'react';
import Box from '@mui/material/Box';

function NewsGrid({ articleOne, articleTwo }: { articleOne: ReactNode; articleTwo: ReactNode }) {
  return (
    <Box
      sx={{
        background: ({ palette }) => palette.primary.main,
        height: '100vh',
        paddingTop: ({ spacing }) => ({ md: spacing(17), xs: spacing(10) }),
        width: '100%',
      }}
    >
      <div
        style={{
          borderBottom: '1px solid white',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            borderBottom: { md: 'unset', xs: '5px solid white' },
            filter: `drop-shadow(5px 0px 0px rgb(255 255 255 / 100%))`,
            height: { md: '100%', xs: '50%' },
            left: 0,
            position: 'absolute',
            top: 0,
            width: { md: '60%', xs: '100%' },
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              background: 'white',
              clipPath: {
                md: `polygon(0 0, 0 100%, 66.6666% 100%, 100% 0)`,
                xs: `polygon(0 0, 0 100%, 100% 100%, 100% 0)`,
              },
              height: '100%',
              width: '100%',
            }}
          >
            {articleOne}
          </Box>
        </Box>
        <Box
          sx={{
            height: { md: '100%', xs: '50%' },
            position: 'absolute',
            right: 0,
            top: { md: 0, xs: '50%' },
            width: { md: '60%', xs: '100%' },
          }}
        >
          <Box
            style={{
              background: 'gray',
              height: '100%',
              width: '100%',
            }}
          >
            {articleTwo}
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export function NewsView() {
  return (
    <NewsGrid
      articleOne={
        <NewsArticleTwo />
        // <NewsArticleCommons
        //   categories={['bodem', 'graafwerk']}
        //   date={new Date(2022, 1, 26, 12, 36)}
        //   side="left"
        //   src={HERO_IMAGE_5}
        //   title="Met Bodem Digitaal Op de Kaart (BDOK) verzamelen wij alle bodemdata en vertalen die direct naar diverse toepassingen."
        // >
        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi.
        //   </NewsArticle.Text>

        //   <NewsArticle.Subtitle>Sed interdum elit at faucibus sagittis.</NewsArticle.Subtitle>

        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi. Praesent condimentum dictum accumsan. Maecenas facilisis, orci id accumsan laoreet,
        //     ante dui pharetra sem, id posuere nunc odio vel libero. Etiam tristique ligula sed purus
        //     efficitur bibendum. Phasellus eget pellentesque diam, sit amet congue nibh. Sed ex dui,
        //     luctus eu aliquam non, varius vitae dolor. Vivamus euismod pulvinar massa, in facilisis
        //     erat ullamcorper sed. Vestibulum ac tempus ante, non aliquet turpis. Nunc sodales sapien
        //     eget dui gravida auctor. Cras ut interdum nunc, vel eleifend felis. Nullam non ante
        //     arcu. Aenean ornare, lorem ac cursus lobortis, nisl nisi venenatis lorem, quis pharetra
        //     tortor quam nec sem. Praesent condimentum nibh nisl, non tincidunt nunc tristique sed.
        //   </NewsArticle.Text>

        //   <NewsArticle.Image src={HERO_IMAGE_5} />

        //   <NewsArticle.Subtitle>
        //     Vivamus euismod pulvinar massa, in facilisis erat ullamcorper sed.
        //   </NewsArticle.Subtitle>

        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi. Praesent condimentum dictum accumsan. Maecenas facilisis, orci id accumsan laoreet,
        //     ante dui pharetra sem, id posuere nunc odio vel libero. Etiam tristique ligula sed purus
        //     efficitur bibendum. Phasellus eget pellentesque diam, sit amet congue nibh. Sed ex dui,
        //     luctus eu aliquam non, varius vitae dolor. Vivamus euismod pulvinar massa, in facilisis
        //     erat ullamcorper sed. Vestibulum ac tempus ante, non aliquet turpis. Nunc sodales sapien
        //     eget dui gravida auctor. Cras ut interdum nunc, vel eleifend felis. Nullam non ante
        //     arcu. Aenean ornare, lorem ac cursus lobortis, nisl nisi venenatis lorem, quis pharetra
        //     tortor quam nec sem. Praesent condimentum nibh nisl, non tincidunt nunc tristique sed.
        //   </NewsArticle.Text>
        // </NewsArticleCommons>
      }
      articleTwo={
        <NewsArticleOne />
        // <NewsArticleCommons
        //   categories={['onderzoek']}
        //   date={new Date(2022, 11, 3, 10, 15)}
        //   imageAuthor="fbar"
        //   src={HERO_IMAGE_2}
        //   textAuthor="bfpo"
        //   title="Versnelt het vooronderzoek bij het werken in en met verontreinigde bodem."
        // >
        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi.
        //   </NewsArticle.Text>

        //   <NewsArticle.Subtitle>Sed interdum elit at faucibus sagittis.</NewsArticle.Subtitle>

        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi. Praesent condimentum dictum accumsan. Maecenas facilisis, orci id accumsan laoreet,
        //     ante dui pharetra sem, id posuere nunc odio vel libero. Etiam tristique ligula sed purus
        //     efficitur bibendum. Phasellus eget pellentesque diam, sit amet congue nibh. Sed ex dui,
        //     luctus eu aliquam non, varius vitae dolor. Vivamus euismod pulvinar massa, in facilisis
        //     erat ullamcorper sed. Vestibulum ac tempus ante, non aliquet turpis. Nunc sodales sapien
        //     eget dui gravida auctor. Cras ut interdum nunc, vel eleifend felis. Nullam non ante
        //     arcu. Aenean ornare, lorem ac cursus lobortis, nisl nisi venenatis lorem, quis pharetra
        //     tortor quam nec sem. Praesent condimentum nibh nisl, non tincidunt nunc tristique sed.
        //   </NewsArticle.Text>

        //   <NewsArticle.Image src={HERO_IMAGE_2} />

        //   <NewsArticle.Subtitle>
        //     Vivamus euismod pulvinar massa, in facilisis erat ullamcorper sed.
        //   </NewsArticle.Subtitle>

        //   <NewsArticle.Text>
        //     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer finibus, lectus vitae
        //     tristique fringilla, elit eros accumsan lorem, at luctus quam erat non turpis. Donec ut
        //     enim aliquam, pulvinar nisl a, pulvinar massa. Aliquam consectetur lectus sed risus
        //     dictum, et pretium est vulputate. Sed interdum elit at faucibus sagittis. Maecenas
        //     hendrerit egestas rutrum. Maecenas sit amet tristique arcu, id condimentum libero. Fusce
        //     ultricies, enim ut varius accumsan, urna dolor lacinia enim, in tristique mi urna sed
        //     mi. Praesent condimentum dictum accumsan. Maecenas facilisis, orci id accumsan laoreet,
        //     ante dui pharetra sem, id posuere nunc odio vel libero. Etiam tristique ligula sed purus
        //     efficitur bibendum. Phasellus eget pellentesque diam, sit amet congue nibh. Sed ex dui,
        //     luctus eu aliquam non, varius vitae dolor. Vivamus euismod pulvinar massa, in facilisis
        //     erat ullamcorper sed. Vestibulum ac tempus ante, non aliquet turpis. Nunc sodales sapien
        //     eget dui gravida auctor. Cras ut interdum nunc, vel eleifend felis. Nullam non ante
        //     arcu. Aenean ornare, lorem ac cursus lobortis, nisl nisi venenatis lorem, quis pharetra
        //     tortor quam nec sem. Praesent condimentum nibh nisl, non tincidunt nunc tristique sed.
        //   </NewsArticle.Text>
        // </NewsArticleCommons>
      }
    />
  );
}
