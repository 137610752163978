import { EmbeddedYoutubeVideo } from '../../../components/EmbeddedYoutubeVideo';
import { FormikErrorListener } from '../../../components/formik-error-listener';
import { ReactElement } from 'react';
import { isIOS } from 'react-device-detect';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import HERO_IMAGE_1 from '../../../assets/images/0043-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import HERO_IMAGE_2 from '../../../assets/images/0045-2022-03-15 Antea Group-Bergen op Zoom asbestsanering.jpg';
import HERO_IMAGE_3 from '../../../assets/images/2020-11-17 project Enexis Past. Moormanstraat te Maastricht-19.jpg';
import HERO_IMAGE_4 from '../../../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-3.jpg';
import HERO_IMAGE_5 from '../../../assets/images/2020-11-17 project Enexis Past. Moormanstraat te Maastricht-14-2.jpg';
import LocalVideoPlayer from '../../../components/LocalVideoPlayer';
import Paper from '@mui/material/Paper';
import RequestDemoPresentationForm from '../../../features/request-demo-presentation/form';
import RequestDemoPresentationFormik from '../../../features/request-demo-presentation/formik';
import Typography from '@mui/material/Typography';
import bdok_propositie_poster from '../../../assets/video/Thumbnail_Propositiefilm.png';
import bdok_propositie_video from '../../../assets/video/BDOK_Propositiefilm.mp4';
import enexisPlaceholder from '../../../assets/images/enexis-placeholder.png';

export default function HomeView(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: ({ spacing }) =>
            `radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) ${spacing(
              40
            )}), url('${HERO_IMAGE_1}')`,
          backgroundPosition: { md: 'center', xs: '25%' },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          paddingTop: ({ spacing }) => ({ md: spacing(17), xs: spacing(10) }),
          width: '100%',
        }}
      >
        <Container
          color="white"
          sx={{
            fontWeight: '500',
            padding: ({ spacing }) => spacing(3),
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Typography
            animate={{ opacity: 1, y: 0 }}
            color="white"
            component={motion.h2}
            initial={{ opacity: 0, y: 50 }}
            sx={{
              fontWeight: '500',
              textAlign: 'center',
            }}
            transition={{ delay: 0.3, type: 'tween' }}
            variant="h2"
          >
            Met Bodem Digitaal Op de Kaart (BDOK) verzamelen wij alle bodemdata en vertalen die
            direct naar diverse toepassingen.
          </Typography>
        </Container>
      </Box>
      <Container
        color="primary"
        component={Typography}
        sx={{
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
          textAlign: 'center',
        }}
        variant="h2"
      >
        Op de BDOK Storingskaart zien monteurs in één oogopslag of ze ergens veilig aan het werk
        kunnen.
      </Container>
      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_2}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '50vh',
          width: '100%',
        }}
      />
      <Container
        maxWidth={false}
        sx={{
          background: ({ palette }) => palette.primary.main,
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
          textAlign: 'center',
        }}
      >
        <Container color="white" component={Typography} variant="h2">
          Door de BDOK Quickscan en de uitgebreide BDOK Combirapportage beschikken werkvoorbereiders
          en engineers in de planfase over de bodemrisico&apos;s en over alle relevante
          bodeminformatie op hun werklocatie.
        </Container>
      </Container>

      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_3}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '50vh',
          width: '100%',
        }}
      />
      <Container
        color="primary"
        component={Typography}
        gutterBottom
        sx={{
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
        }}
        textAlign="center"
        variant="h2"
      >
        Met BDOK krijg je direct inzicht in de bodemkwaliteit en alle veiligheidsrisico&apos;s.
      </Container>

      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_5}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '50vh',
          width: '100%',
        }}
      />
      <Container
        sx={{
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
        }}
      >
        <div>
          <LocalVideoPlayer poster={bdok_propositie_poster} src={bdok_propositie_video} />
        </div>
      </Container>

      <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
        <EmbeddedYoutubeVideo
          placeholder={enexisPlaceholder}
          src="https://www.youtube-nocookie.com/embed/EF1cPPhSbHM"
          title="#AGdients | Bodem Digitaal Op De Kaart (BDOK)"
        />
      </Container>

      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_4}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
          width: '100%',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Paper sx={{ minWidth: { sm: '600px', xs: '100%' }, px: 3, py: 5, width: '61.8%' }}>
            <Typography color="secondary" fontStyle="italic" sx={{ pb: 5 }} variant="h4">
              Wil je meer weten over hoe BDOK werkt? Vraag dan hier gratis een demo-presentatie aan!
            </Typography>
            <RequestDemoPresentationFormik>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {(formikProps) => (
                <>
                  <FormikErrorListener
                    onError={() => {
                      enqueueSnackbar('Niet alle velden zijn (correct) ingevuld.', {
                        variant: 'warning',
                      });
                    }}
                  />
                  <RequestDemoPresentationForm {...formikProps} />
                </>
              )}
            </RequestDemoPresentationFormik>
          </Paper>
        </Container>

        {/* <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={closeDialog}
          open={open}
        >
          <DialogTitle id="alert-dialog-title">
            Uw aanvraag is correct door ons ontvangen.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Binnen enkele dagen nemen wij met u contact op. Groeten, team BDOK
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog}>
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}
      </Box>
    </>
  );
}
