import { NewsArticle, NewsArticleCommons } from './news-article';
import image2 from './0037-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import image3 from './0034-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import image4 from './2021-12-02 Relinen binnenstad Maastricht - Antea Group-11.jpg';

export function NewsArticleTwo() {
  return (
    <NewsArticleCommons
      categories={['bodem', 'samenwerking']}
      date={new Date(2023, 1, 1, 12, 0)}
      imageAuthor="Antea Group"
      side="left"
      src={image3}
      textAuthor="Antea Group"
      title="Samenwerking GOconnectIT en Bodem Digitaal Op de Kaart (BDOK) biedt grondroerders inzicht in de ondergrond"
    >
      <NewsArticle.Introduction>
        Jaarlijkse worden er meer dan 700 duizend graafactiviteiten uitgevoerd waarbij informatie
        over de bodem noodzakelijk is. Daarbij worden vaak onnodige bodemonderzoeken gedaan om
        CROW400 compliant te kunnen zijn. Het verkrijgen en interpreteren van “bodemdata” wordt
        veelal als complex gezien. Door het hergebruik van bodemdata kan er veel onnodig
        bodemonderzoek worden vermeden. Als het aan GOconnectIT en BDOK – een samenwerkingsverband
        tussen Antea Group en Nazca Solutions – ligt komt aan onnodig bodemonderzoek snel een einde.
      </NewsArticle.Introduction>

      <NewsArticle.Image src={image3} />

      <NewsArticle.Text>
        GOconnectIT is marktleider met innovatie oplossingen in het domein kabels en leidingen. BDOK
        is marktleider in het CROW400 domein, met veel kennis rondom de verplichtingen van de
        CROW400. BDOK heeft een landelijke historische bodemdatabase waarmee de CROW400 verplichting
        kan worden ingevuld. Door onze expertises te bundelen worden grondroerders optimaal voorzien
        van informatie. Daarmee kan onvoorziene vertraging worden voorkomen en kan er veiliger
        worden gewerkt. Onlangs hebben GOconnectIT en BDOK hiertoe een samenwerkingsovereenkomst
        ondertekend.
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Grote behoefte aan informatie over de omgeving</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Het wordt om verschillende redenen steeds drukker in de ondergrond. Onder andere de
        energietransitie, de vervangingsopgave van verouderde netwerken en het versneld uitrollen
        van glasvezelnetten resulteren in een groeiend aantal grondroeringen. Efficiënte
        werkprocessen zijn dan ook belangrijker dan ooit.
      </NewsArticle.Text>

      <NewsArticle.Image src={image4} />

      <NewsArticle.Subtitle>Koppeling GO InfraMaps en BDOK</NewsArticle.Subtitle>

      <NewsArticle.Text>
        “Grondroerders hebben dan ook een grote behoefte aan informatie over de omgeving, zowel
        boven als onder de grond. Als zij weten wat zij op een locatie aantreffen en tegelijkertijd
        kunnen inschatten wat de impact is van een project op de omgeving, kunnen zij een gerichte
        planning maken”, legt Jan Smit uit, business development manager bij GOconnectIT. “Met ons
        GIS-platform GO InfraMaps wordt de data van de boven- en ondergrondse situaties in samenhang
        gevisualiseerd. Zo beschikken grondroerders, mede door de koppeling met BDOK, in één
        oogopslag over waardevolle data die hun werk een stuk eenvoudiger maken.”
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Alle bodemdata verzameld</NewsArticle.Subtitle>

      <NewsArticle.Text>
        “Het verkrijgen van bodeminformatie is een tijdrovend proces. Je zult vele verschillende
        bronnen moeten raadplegen zoals historische activiteiten, bodemkwaliteitskaarten, eerder
        uitgevoerde bodemonderzoeken en saneringen”, vertelt René Rummens, productmanager BDOK. “We
        hebben met Bodem Digitaal Op de Kaart (BDOK) alle bodemdata verzameld. Met BDOK weet je
        precies wanneer je kunt graven, welke maatregelen en/of procedures moeten worden genomen en
        of aanvullend bodemonderzoek nodig is. Door de samenwerking met GOconnectIT is deze
        waardevolle data direct beschikbaar in het gebruikersvriendelijke GO InfraMaps. Als
        werkvoorbereider, projectleider, uitvoerder en als civiel medewerker heb je alle benodigde
        bodemdata letterlijk binnen handbereik.”
      </NewsArticle.Text>

      <NewsArticle.Image src={image2} />

      <NewsArticle.Subtitle>Combinatie van twee marktleiders</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Door de samenwerking tussen GOconnectIT en BDOK kun je in een vroeg stadium van een project
        beschikken over alle relevante ondergrondse gebiedsdata. Zo ben je er zeker van dat je
        voldoet aan de geldende wet- en regelgeving. Je kunt tevens passende maatregelen nemen om
        problemen tijdens graafwerkzaamheden te voorkomen, zoals het aanpassen van het werkplan of
        het anders projecteren van kabels en leidingen. Daarmee voorkom je dat je tegen onverwachte
        uitgaven aanloopt. “Met BDOK en GOconnectIT heb je een combinatie van twee marktleiders die
        samen veel kennis en ervaring hebben als het gaat om bodem en ondergrond. Ik kijk uit naar
        een succesvolle samenwerking”, besluit Jan Smit.
      </NewsArticle.Text>
    </NewsArticleCommons>
  );
}
