import { ReactElement, useState } from 'react';
import { useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ChurchIcon from '@mui/icons-material/Church';
import ClearIcon from '@mui/icons-material/Clear';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import Typography from '@mui/material/Typography';

export default function ContactButtonAndDrawer(): ReactElement {
  const [state, setState] = useState(false);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };
  const { breakpoints } = useTheme();
  const mediumScreen = useMediaQuery(breakpoints.up('md'));
  return (
    <>
      <Typography
        color={trigger ? 'primary' : 'white'}
        component="button"
        onClick={toggleDrawer(true)}
        sx={{
          '&:hover': {
            color: ({ palette }) => palette.secondary.main,
          },
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: { md: 'flex', xs: 'none' },
          fontWeight: 600,
          textDecoration: 'none',
          transition: 'color 0.3s ease',
        }}
        variant="body1"
      >
        Contact
      </Typography>
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{ color: trigger ? 'primary.main' : 'white', display: { md: 'none', xs: 'flex' } }}
      >
        <ContactPageIcon />
      </IconButton>
      <Drawer
        anchor={mediumScreen ? 'right' : 'bottom'}
        disableScrollLock={!mediumScreen}
        onClose={toggleDrawer(false)}
        open={state}
        PaperProps={{
          sx: ({ spacing }) => ({
            borderRadius: mediumScreen
              ? `${spacing(1)} 0px 0px ${spacing(1)}`
              : `${spacing(1)} ${spacing(1)} 0px 0px `,
            height: mediumScreen ? '100%' : '95%',
            width: mediumScreen ? '38.2%' : '100%',
          }),
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Box
            sx={({ spacing }) => ({
              alignItems: 'center',
              display: 'flex',
              flexShrink: 0,
              height: spacing(8),
              justifyContent: 'flex-end',
              px: spacing(3),
            })}
          >
            <IconButton
              onClick={toggleDrawer(false)}
              size="small"
              sx={{
                transform: 'translateX(10px)', // Move the icon to the left so the right side of the icon matches the padding instead of the right side of the touchripple
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
          <Box
            sx={({ spacing }) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: spacing(3),
            })}
          >
            <Typography color="secondary" gutterBottom variant="h2">
              Contact
            </Typography>

            <Box alignItems="center" display="flex" gap={4} sx={{ mb: { lg: 4, xs: 1 } }}>
              <Typography color="primary" variant="h5">
                Kom gerust een kop koffie drinken in ons prachtige kantoor.
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" gap={4} mb={{ md: 3, xs: 1 }}>
              <ChurchIcon color="primary" fontSize={mediumScreen ? 'large' : 'medium'} />
              <Typography color="primary" component="span" variant="h5">
                Haagweg 1 <br />
                4814 GA Breda
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" gap={4} mb={{ md: 3, xs: 1 }}>
              <MailIcon color="primary" fontSize={mediumScreen ? 'large' : 'medium'} />
              <Typography color="primary" component="span" variant="h5">
                <Box
                  component="a"
                  href="mailto:klantenservice@bdok.nl"
                  sx={{ color: 'secondary.main', textDecoration: 'none' }}
                >
                  klantenservice@bdok.nl
                </Box>
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" gap={4}>
              <PhoneIcon color="primary" fontSize={mediumScreen ? 'large' : 'medium'} />
              <Typography color="primary" component="span" variant="h5">
                <Box
                  component="a"
                  href="tel:0307114704"
                  sx={{ color: 'secondary.main', textDecoration: 'none' }}
                >
                  030 711 4704
                </Box>
              </Typography>
            </Box>

            <Typography
              color="primary"
              sx={{
                // display: { lg: 'block', xs: 'none' },
                my: { md: 4, xs: 2 },
              }}
              variant="body1"
            >
              BDOK is een samenwerkingsverband tussen{' '}
              <Box
                component="a"
                href="https://anteagroup.nl/"
                rel="noreferrer"
                sx={{ color: 'secondary.main', textDecoration: 'none' }}
                target="_blank"
              >
                Antea Group
              </Box>{' '}
              en{' '}
              <Box
                component="a"
                href="https://www.nazcai.nl/"
                rel="noreferrer"
                sx={{ color: 'secondary.main', textDecoration: 'none' }}
                target="_blank"
              >
                Nazca Solutions B.V.
              </Box>
            </Typography>
          </Box>
          {/* <Box
            sx={{
              background: `url('${CHURCH_PHOTO}')`,
              backgroundPosition: 'bottom',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              flex: 1,
              width: 1,
            }}
          /> */}
          <Box
            component="iframe"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.083412815767!2d4.760927215973919!3d51.58503461284449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6a020e8ed3c27%3A0xb76088e7f29bafa4!2sHaagweg%201%2C%204814%20GA%20Breda!5e0!3m2!1snl!2snl!4v1651850959090!5m2!1snl!2snl"
            sx={{
              border: 0,
              flex: 1,
              minHeight: '250px',
              width: 1,
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}
