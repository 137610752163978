/* eslint-disable react/jsx-props-no-spreading */
import { AboutCompanyCard } from '../../../components/AboutCompanyCard';
import { AnimatePresence, motion } from 'framer-motion';
import { Carousel } from '../../../components/Carousel';
import { ReactElement, useState } from 'react';
import { Timeline } from '../../../components/Timeline';
import { isIOS } from 'react-device-detect';
import { makeMotionProps, makeVariantLabelProps } from '../../../motion';
import { useTheme } from '@mui/material';
import AnteaGroupLogo from '../../../components/AnteaGroupLogo';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import HERO_IMAGE_1 from '../../../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-21.jpg';
import HERO_IMAGE_2 from '../../../assets/images/IMG_20220502_194029.jpg';
import HERO_IMAGE_3 from '../../../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-7.jpg';
import NazcaSolutionsLogo from '../../../components/NazcaSolutionsLogo';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const iconMotionProps = makeMotionProps(makeVariantLabelProps('hidden', 'visible'), {
  hidden: {
    originX: '50%',
    originY: '50%',
    scale: 0,
  },
  visible: {
    originX: '50%',
    originY: '50%',
    scale: 1,
  },
});
const titleMotionProps = makeMotionProps(makeVariantLabelProps('hidden', 'visible'), {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
});

export default function AboutBDOKView(): ReactElement {
  const [visible, setVisible] = useState(false);
  const { spacing } = useTheme();

  return (
    <Container disableGutters maxWidth={false}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) ${spacing(
            40
          )}), url('${HERO_IMAGE_1}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          height: '40vh',
          justifyContent: 'center',
          minHeight: '200px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Container
          color="white"
          sx={{
            bottom: '50%',
            position: 'absolute',
            transform: {
              md: `translateY(calc(50% + (${spacing(17)} / 2)))`,
              xs: `translateY(calc(50% + (${spacing(10)} / 2)))`,
            },
          }}
        >
          <Typography color="white" sx={{ fontWeight: '700' }} variant="h2">
            Over BDOK
          </Typography>
        </Container>
      </Box>
      <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
        <Typography color="primary" variant="h4">
          BDOK is een samenwerkingsverband tussen de bedrijven Antea Group en Nazca Solutions. Antea
          Group levert als ingenieursbureau domeinkennis en zorgt voor het klantcontact. Nazca
          Solutions zorgt voor een vlekkeloze werking van de dienst en levert haar unieke
          bodemdatabase. Door deze unieke samenwerking zijn kennis en techniek samengevoegd en dat
          leidt tot de producten van BDOK.
        </Typography>
      </Container>
      <Paper elevation={5} square>
        <Container
          component={motion.div}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            component={ButtonBase}
            disableRipple
            disableTouchRipple
            onClick={() => {
              if (visible === false) setVisible(true);
            }}
            sx={{
              alignItems: 'center',
              cursor: visible ? 'default' : 'pointer',
              display: 'flex',
              gap: 3,
              justifyContent: 'flex-start',
              minHeight: spacing(20),

              py: 5,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {visible ? (
                <Typography
                  key="opened-title"
                  color="secondary"
                  component={motion.h4}
                  variant="h4"
                  {...titleMotionProps}
                  textAlign="left"
                  transition={{ duration: 0.2, type: 'tween' }}
                >
                  Over Antea Group en Nazca Solutions
                </Typography>
              ) : (
                <Typography
                  key="unopened-title"
                  color="secondary"
                  component={motion.h4}
                  variant="h4"
                  {...titleMotionProps}
                  textAlign="left"
                  transition={{ duration: 0.2, type: 'tween' }}
                >
                  Klik hier voor meer informatie over Antea Group en Nazca Solutions
                </Typography>
              )}
            </AnimatePresence>

            <Box
              onClick={() => {
                setVisible((s) => !s);
              }}
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
              }}
            >
              <AnimatePresence exitBeforeEnter>
                {visible ? (
                  <UnfoldLessIcon
                    key="unfold-more"
                    color="primary"
                    component={motion.svg}
                    fontSize="large"
                    {...iconMotionProps}
                    transition={{ duration: 0.2, type: 'tween' }}
                  />
                ) : (
                  <UnfoldMoreIcon
                    key="unfold-less"
                    color="primary"
                    component={motion.svg}
                    fontSize="large"
                    {...iconMotionProps}
                    transition={{ duration: 0.2, type: 'tween' }}
                  />
                )}
              </AnimatePresence>
            </Box>
          </Box>

          <Box
            animate={{ height: visible ? 'auto' : 0, opacity: visible ? 1 : 0 }}
            component={motion.div}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              overflow: 'hidden',
            }}
            transition={{
              default: { duration: 0.2, type: 'tween' },
              opacity: { delay: visible ? 0.2 : 0, duration: 0.2 },
            }}
          >
            <AboutCompanyCard
              body="Antea Group is een internationaal ingenieurs- en adviesbureau; gespecialiseerd in full-service oplossingen op het gebied van milieu, infrastructuur, stedenbouw en water."
              logo={AnteaGroupLogo}
              name="Antea Group"
              url="https://anteagroup.nl/diensten/milieu-en-omgevingsdata/bodem-digitaal-op-de-kaart"
            />
            <AboutCompanyCard
              body="Nazca Solutions ontwikkelt innovatieve oplossingen voor het verzamelen, opslaan en ontsluiten van gegevens over zowel de boven- als ondergrond. Nazca Solutions verzorgt en realiseert real-time data die 24/7 online beschikbaar is, voor o.a. Track & Trace oplossingen, Internet-of-Things-toepassingen, bodembeheer, online rapportages en nog veel meer voor een effectief resultaat."
              logo={NazcaSolutionsLogo}
              name="Nazca Solutions"
              // sx={{ mb: 7 }}
              url="https://nazcasolutions.nl/over-nazca/"
            />

            <Box
              sx={{
                height: ({ spacing }) => spacing(7),
              }}
            />
          </Box>
        </Container>
      </Paper>

      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_2}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '40vh',
          width: '100%',
        }}
      />
      <Container
        maxWidth={false}
        sx={{
          background: ({ palette }) => palette.primary.main,
          color: 'white',
        }}
      >
        <Timeline
          year1={2016}
          year1Text="In 2016 kwam René Rummens van Antea Group op het idee de data uit bodemonderzoeken te hergebruiken voor andere doeleinden; het geven van advies over het werken in en met verontreinigde grond. De huidige CROW400 geeft hier invulling aan. Samen met Nus Jurgens van Nazca Solutions werden de handen in elkaar geslagen om hiervoor een digitale oplossing te ontwikkelen; BDOK."
          year2={2023}
          year2Text="Nu, anno 2023 bevat de BDOK applicatie niet alleen de meest uitgebreide en volledige bodemdatabase van Nederland, maar is er een applicatie gebouwd die snel een eenvoudig inzicht geeft in de bodemkwaliteit, de veiligheidsrisico’s en nog veel meer!"
        />
      </Container>
      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_3}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '40vh',
          width: '100%',
        }}
      />
      <Box sx={{ pb: 15, pt: 20 }}>
        <Container>
          <Carousel />
        </Container>
      </Box>
    </Container>
  );
}
