import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Path } from '../../../PATHS';

export type Props = Omit<Path, 'id'>;

export default function NavTile({ image, title, to }: Props): ReactElement | null {
  const resolved = useResolvedPath(to);
  const match = useMatch({ end: true, path: resolved.pathname });

  if (match) return null;

  return (
    <Grid item sm={4}>
      <Paper
        elevation={3}
        sx={({ palette }) => ({
          color: palette.background.paper,
          height: { sm: '200px', xs: '150px' },
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
        })}
      >
        <Box
          component={Link}
          sx={{
            '&:hover': {
              '> img': { filter: 'saturate(1)', transform: 'scale(1.1)' },
            },
            '> img': {
              filter: 'saturate(0.6)',
              transform: 'scale(1)',
              transition: 'all 0.3s ease',
            },
          }}
          target={typeof to !== 'string' ? '_blank' : undefined}
          to={to}
        >
          <img
            alt=""
            src={image}
            style={{
              display: 'block',
              height: 'auto',
              minHeight: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        </Box>

        <Typography
          sx={({ spacing }) => ({
            bottom: spacing(2),
            fontWeight: 'bold',
            left: spacing(2),
            pointerEvents: 'none',
            position: 'absolute',
            textShadow: '2px 2px 2px rgba(0,0,0,0.5)',
          })}
          variant="h6"
        >
          {title}
        </Typography>
      </Paper>
    </Grid>
  );
}
