import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CAROUSEL_IMAGE_1 from '../assets/images/Afbeelding1.jpg';
import CAROUSEL_IMAGE_2 from '../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-16.jpg';
import CAROUSEL_IMAGE_3 from '../assets/images/2020-11-17 project Enexis Past. Moormanstraat te Maastricht-10.jpg';
import CAROUSEL_IMAGE_4 from '../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-5.jpg';
import Paper from '@mui/material/Paper';

const CAROUSEL_IMAGES = [CAROUSEL_IMAGE_1, CAROUSEL_IMAGE_2, CAROUSEL_IMAGE_3, CAROUSEL_IMAGE_4];

export function Carousel() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex((i) => (i + 1 >= CAROUSEL_IMAGES.length ? 0 : i + 1));
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [index]);

  return (
    <Box>
      <Box>
        <motion.div animate />
        <Box sx={{ height: { md: '70vh', xs: '60vh' }, position: 'relative', width: 1 }}>
          <AnimatePresence>
            {CAROUSEL_IMAGES.map((image, i) =>
              index === i ? (
                <Paper
                  // eslint-disable-next-line react/no-array-index-key
                  key={`image-${i}`}
                  alt=""
                  animate={{ opacity: 1 }}
                  component={motion.img}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  src={image}
                  sx={{ height: 1, objectFit: 'cover', position: 'absolute', width: 1 }}
                  transition={{ duration: 0.5 }}
                />
              ) : null
            )}
          </AnimatePresence>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
          pt: 3,
          width: 1,
        }}
      >
        {CAROUSEL_IMAGES.map((img, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`carrousel-pagination-${i}`}
            animate={{ opacity: index === i ? 1 : 0.5, scale: index === i ? 1.5 : 1 }}
            component={motion.div}
            layout
            onClick={() => {
              setIndex(i);
            }}
            sx={{
              background: ({ palette }) => palette.primary.main,
              borderRadius: '50%',
              cursor: 'pointer',
              height: '10px',
              width: '10px',
            }}
            whileHover={{ opacity: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
}
