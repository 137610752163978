import GlobalStyles from '@mui/material/GlobalStyles';

const htmlGlobalStyles = (
  <GlobalStyles
    styles={() => ({
      '#root': {
        height: '100%',
        width: '100%',
      },
      '*': {
        boxSizing: 'border-box',
      },
      body: {
        background: '#F3F4F7',
        height: '100%',
        width: '100%',
      },
      html: {
        height: '100%',
        overflow: 'overlay',
        width: '100%',
      },
    })}
  />
);

export default htmlGlobalStyles;
