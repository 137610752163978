import * as Yup from 'yup';
import { Formik, FormikConfig } from 'formik';
import { ReactElement } from 'react';
import { SK } from '../../../constants';
import { add, format } from 'date-fns';
import { isDate } from 'lodash';
import { useSnackbar } from 'notistack';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

declare const API_URL: string;

export interface FormValues {
  agree?: boolean;
  dateTime: string;
  emailAddress: string;
  firstName: string;
  insertion?: string;
  lastName: string;
  proficiency: string;
}

export type RequestDemoPresentationFormikProps = Omit<
  FormikConfig<FormValues>,
  'initialValues' | 'validationSchema' | 'onSubmit' | 'validateOnBlur'
>;

export default function BDOKAcademyFormik(props: RequestDemoPresentationFormikProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = {
    agree: false,
    dateTime: `${new Date(2023, 2, 16, 11, 0).getTime()}-${new Date(2023, 2, 16, 12, 0).getTime()}`,
    emailAddress: '',
    firstName: '',
    insertion: '',
    lastName: '',
    proficiency: 'Beginner',
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    agree: Yup.boolean()
      .test({
        message: 'U moet akkoord gaan met de voorwaarden.',
        name: 'isAgreed',
        test: (value) => !!value === true,
      })
      .required('U moet akkoord gaan met de inschrijving en het verschuldigde bedrag'),
    dateTime: Yup.string()
      // .test({
      //   message: 'Ongeldige datum',
      //   name: 'isValidDateTime',
      //   test: (value) => !!value && isDate(new Date(value)),
      // })
      .required(),
    emailAddress: Yup.string()
      .email('Voer een geldig E-mail adres in.')
      .required('E-mail adres is verplicht.'),
    firstName: Yup.string()
      .min(3, 'Voornaam moet ten minste 3 karakters bevatten.')
      .max(50, 'Voornaam moet ten meeste 50 karakters bevatten.')
      .required('Voornaam is verplicht'),
    insertion: Yup.string()
      .min(1, 'Tussenvoegsel moet ten minste 1 karakter bevatten.')
      .max(50, 'Voornaam moet ten meeste 50 karakters bevatten.')
      .notRequired(),
    lastName: Yup.string()
      .min(3, 'Acternaam moet ten minste 3 karakters bevatten.')
      .max(50, 'Acternaam moet ten meeste 50 karakters bevatten.')
      .required('Acternaam is verplicht'),
    proficiency: Yup.string()
      .test({
        message: 'Ongeldig ervaringsniveau',
        name: 'isValidProficiencyLevel',
        test: (value) => value === 'Beginner' || value === 'Ervaren',
      })
      .required('Ervaringsniveau is verplicht'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formValues, { resetForm, setSubmitting }) => {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${SK}`,
          },
        };

        const handleFullfilled = () => {
          enqueueSnackbar(
            'Uw aanvraag is correct door ons ontvangen. Binnen enkele dagen nemen wij met u contact op. Groeten, team BDOK',
            { variant: 'success' }
          );
          setSubmitting(false);
          resetForm();
        };

        const handleRejected = () => {
          enqueueSnackbar(
            'Er ging wat mis met het versturen van het formulier. Probeer het nog eens.',
            { variant: 'error' }
          );
          setSubmitting(false);
        };

        const handleCatch = () => {
          enqueueSnackbar(
            'Er ging wat mis met het versturen van het formulier. Probeer het nog eens.',
            { variant: 'error' }
          );
          setSubmitting(false);
        };

        const [startDateTime, endDateTime] = `${formValues.dateTime}`.split('-');
        axios
          .put<'', AxiosResponse<''>, Omit<FormValues, 'dateTime'> & { dateTime: string }>(
            `${API_URL}/Academy`,
            {
              ...formValues,
              dateTime:
                format(parseInt(startDateTime, 10), 'dd-MM-yyyy HH:mm') +
                format(parseInt(endDateTime, 10), '-HH:mm'),
            },
            config
          )
          .then(handleFullfilled, handleRejected)
          .catch(handleCatch);
      }}
      // validateOnBlur={false}
      // validateOnChange={false}
      validationSchema={validationSchema}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
