import { ReactElement, useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { motion } from 'framer-motion';
import { useHover } from './use-hover';
import Box from '@mui/material/Box';

const isVideoPlaying = (video: HTMLVideoElement | null) =>
  !isNull(video) &&
  !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

const sizes: Record<'normal' | 'small', [number, number, number, number]> = {
  normal: [74, 120, 148, 10],
  small: [36, 60, 74, 5],
};

export default function LocalVideoPlayer({
  poster,
  src,
  variant = 'normal',
}: {
  poster?: string;
  src: string;
  variant?: 'normal' | 'small';
}): ReactElement {
  const [hasPlayedAtLeastOnce, setHasPlayedAtLeastOnce] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);

  const [ref, isHovered] = useHover<HTMLVideoElement>();

  const [size1, size2, size3, offset] = sizes[variant];

  useEffect(() => {}, []);

  return (
    <Box
      sx={{
        '.button': {
          background: 'transparent',
          border: '0',
          borderColor: (theme) =>
            `transparent transparent transparent ${theme.palette.secondary.main}`,
          borderStyle: 'solid',
          borderWidth: `${size1}px 0 ${size1}px ${size2}px`,
          boxSizing: 'border-box',
          cursor: 'pointer',
          height: `${size3}px`,
          transition: '100ms all ease',
          width: '0',
        },
        '.button-wrapper': {
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: `translate(-50%, -50%) translateX(${offset}px)`,
          zIndex: 1,
        },
        '.button.paused': {
          borderStyle: 'double',
          borderWidth: `0px 0 0px ${size2}px`,
        },
        '.button:hover': {
          borderColor: (theme) =>
            `transparent transparent transparent ${theme.palette.secondary.light}`,
        },
        height: 1,
        position: 'relative', // py: { lg: 20, md: 15, sm: 10, xs: 5 },
        width: 1,
      }}
    >
      <div className="button-wrapper">
        <motion.button
          animate={{
            opacity:
              isHovered || !hasPlayedAtLeastOnce || (ref.current && ref.current.paused) ? 1 : 0,
          }}
          className={`button ${isPlaying ? 'paused' : ''}`}
          onClick={() => {
            const video = ref.current;

            if (!video) return;

            if (isPlaying) video.pause();
            else video.play();

            // e.currentTarget.classList.toggle('paused');
          }}
          whileHover={{ opacity: 1, scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={ref}
        controls={hasPlayedAtLeastOnce}
        height="100%"
        onEnded={() => {
          setIsPlaying(false);
        }}
        onPause={() => {
          setIsPlaying(false);
        }}
        onPlay={() => {
          if (!hasPlayedAtLeastOnce) setHasPlayedAtLeastOnce(true);

          setIsPlaying(true);
        }}
        poster={poster}
        src={src}
        width="100%"
      >
        Sorry, uw browser ondersteunt geen HTML5 videos.
      </video>
    </Box>
  );
}
