import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { FormikErrorListener } from '../../../components/formik-error-listener';
import { isIOS } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import BDOKAcademyForm from '../../../features/bdok-academy/form';
import BDOKAcademyFormik from '../../../features/bdok-academy/formik';
import HERO_IMAGE_1 from '../../../assets/images/0051-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import HERO_IMAGE_2 from '../../../assets/images/2020-11-17 project Enexis Past. Moormanstraat te Maastricht-14-2.jpg';

export default function WhatIsBDOKView(): ReactElement {
  const { spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Container disableGutters maxWidth={false}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) ${spacing(
            40
          )}), url('${HERO_IMAGE_1}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          height: '40vh',
          justifyContent: 'center',
          minHeight: '200px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Container
          color="white"
          sx={{
            bottom: '50%',
            position: 'absolute',
            transform: {
              md: `translateY(calc(50% + (${spacing(17)} / 2)))`,
              xs: `translateY(calc(50% + (${spacing(10)} / 2)))`,
            },
          }}
        >
          <Typography color="white" sx={{ fontWeight: '700' }} variant="h2">
            BDOK Academy
          </Typography>
        </Container>
      </Box>
      <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="primary" sx={{ fontStyle: 'italic' }} variant="h3">
              Kennis up-to-date houden
            </Typography>
          </Grid>
          <Grid item sx={{ alignItems: 'center', display: 'flex' }} xs={12}>
            <Typography color="primary" variant="h4">
              Voor onze bestaande klanten hebben wij de BDOK Academy in het leven geroepen. Bij
              organisaties komen en gaan medewerkers en daarmee gaat ook bestaande kennis verloren.
              Om up-to-date te blijven en de nieuwe medewerkers kennis te laten maken met BDOK,
              kunnen deze medewerkers zich aanmelden voor de maandelijkse sessie van de BDOK
              Academy.
              <br />
              <br />
              Voor een sessie betaal je het symbolische bedrag van één BDOK Quickscan, te weten
              €33,-.
              <br />
              <br />
              Via onderstaand formulier kun je je aanmelden.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_2}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
          width: '100%',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper sx={{ minWidth: { sm: '600px', xs: '100%' }, px: 3, py: 5, width: '61.8%' }}>
            <Typography color="secondary" fontStyle="italic" sx={{ pb: 5 }} variant="h4">
              Aanmelden voor BDOK Academy.
            </Typography>
            <BDOKAcademyFormik>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {(formikProps) => (
                <>
                  <FormikErrorListener
                    onError={() => {
                      enqueueSnackbar('Niet alle velden zijn (correct) ingevuld.', {
                        variant: 'warning',
                      });
                    }}
                  />
                  <BDOKAcademyForm {...formikProps} />
                </>
              )}
            </BDOKAcademyFormik>
          </Paper>
        </Container>
      </Box>
    </Container>
  );
}
