import { AnimatePresence, motion } from 'framer-motion';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { Path } from '../../../PATHS';
import { ReactElement } from 'react';
import { useScrollTrigger } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material//Typography';

const ActiveLinkIndicator = motion(Box);

export type NavBarProps = Omit<Path, 'image' | 'id'>;

export default function NavBarItem({ title, to }: NavBarProps): ReactElement {
  const resolved = useResolvedPath(to);
  const match = useMatch({ end: true, path: resolved.pathname });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <Box
      sx={{
        '&:before': {
          background: ({ palette }) => palette.secondary.main,
          bottom: '0',
          content: '""',
          height: ({ spacing }) => spacing(0.25),
          left: '51%',
          position: 'absolute',
          right: '51%',
          transition: 'all 0.2s ease',
          zIndex: '-1',
        },
        '&:hover': {
          '&:before': {
            left: '25%',
            right: '25%',
          },
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Typography
        key={to}
        color={trigger ? 'primary' : 'white'}
        component={Link}
        sx={{
          fontWeight: 600,
          textDecoration: 'none',
          transition: 'all 0.3s ease',
        }}
        to={to}
        variant="h6"
      >
        {title}
      </Typography>

      <AnimatePresence>
        {match && (
          <ActiveLinkIndicator
            // layoutId="active-link-indicator"
            animate={{
              width: '100%',
            }}
            exit={{ width: '0%' }}
            initial={{
              width: '50%',
            }}
            sx={{
              background: ({ palette }) => palette.secondary.main,
              bottom: '0',
              content: '""',
              height: ({ spacing }) => spacing(0.25),
              position: 'absolute',
              zIndex: '-1',
            }}
            transition={{
              duration: 0.2,
            }}
          />
        )}
      </AnimatePresence>
    </Box>
  );
}
