import { MutableRefObject, useEffect, useRef, useState } from 'react';

export function useHover<T extends HTMLElement>(): [
  MutableRefObject<T | null>,
  boolean
] {
  const [state, setState] = useState(false);

  const ref = useRef<T | null>(null);

  const handleMouseOver = () => setState(true);
  const handleMouseLeave = () => setState(false);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }

    return undefined;
  }, [ref.current]);

  return [ref, state];
}
