import * as Yup from 'yup';
import { Formik, FormikConfig } from 'formik';
import { ReactElement } from 'react';
import { useSnackbar } from 'notistack';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { SK } from '../../../constants';

declare const API_URL: string;

export interface FormValues {
  company: string;
  emailAddress: string;
  firstName: string;
  insertion?: string;
  lastName: string;
  phoneNumber: string;
}

export interface RequestDemoPresentationFormikProps
  extends Omit<
    FormikConfig<FormValues>,
    'initialValues' | 'validationSchema' | 'onSubmit' | 'validateOnBlur'
  > {
  onCatch?: (reason: any) => void;
  onFullfilled?: () => void;
  onRejected?: (reason: any) => void;
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export default function RequestDemoPresentationFormik({
  onFullfilled = () => {},
  onRejected = () => {},
  onCatch = () => {},
  ...props
}: RequestDemoPresentationFormikProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = {
    company: '',
    emailAddress: '',
    firstName: '',
    insertion: '',
    lastName: '',
    phoneNumber: '',
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
    company: Yup.string()
      .min(3, 'Bedrijfsnaam moet ten minste 3 karakters bevatten.')
      .max(100, 'Bedrijfsnaam moet ten meeste 50 karakters bevatten.')
      .required('Bedrijfsnaam is verplicht'),
    emailAddress: Yup.string()
      .email('Voer een geldig E-mail adres in.')
      .required('E-mail adres is verplicht.'),
    firstName: Yup.string()
      .min(3, 'Voornaam moet ten minste 3 karakters bevatten.')
      .max(50, 'Voornaam moet ten meeste 50 karakters bevatten.')
      .required('Voornaam is verplicht'),
    insertion: Yup.string()
      .min(1, 'Tussenvoegsel moet ten minste 1 karakter bevatten.')
      .max(50, 'Voornaam moet ten meeste 50 karakters bevatten.')
      .notRequired(),
    lastName: Yup.string()
      .min(3, 'Acternaam moet ten minste 3 karakters bevatten.')
      .max(50, 'Acternaam moet ten meeste 50 karakters bevatten.')
      .required('Acternaam is verplicht'),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, 'Voer een geldig telefoonnummer in.')
      .required('Telefoonnumer is verplicht.'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formValues, { resetForm, setSubmitting }) => {
        const config: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${SK}`,
          },
        };

        const handleFullfilled = () => {
          enqueueSnackbar(
            'Uw aanvraag is correct door ons ontvangen. Binnen enkele dagen nemen wij met u contact op. Groeten, team BDOK',
            { variant: 'success' }
          );
          onFullfilled();
          setSubmitting(false);
          resetForm();
        };

        const handleRejected = (reason: any) => {
          console.log(reason);
          enqueueSnackbar(
            'Er ging wat mis met het versturen van het formulier. Probeer het nog eens.',
            { variant: 'error' }
          );
          onRejected(reason);
          setSubmitting(false);
        };

        const handleCatch = (reason: any) => {
          console.log(reason);
          enqueueSnackbar(
            'Er ging wat mis met het versturen van het formulier. Probeer het nog eens.',
            { variant: 'error' }
          );
          onCatch(reason);
          setSubmitting(false);
        };

        // console.log({ formValues });

        axios
          .put<'', AxiosResponse<''>, FormValues>(API_URL, formValues, config)
          .then(handleFullfilled, handleRejected)
          .catch(handleCatch);
      }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
