/** Expands object types one level deep */
export type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never;

export type SimpleVariantLabelProps<A extends string> = Expand<{ animate: A }>;

export type MountVariantLabelProps<I extends string, A extends string> = Expand<{
  animate: A;
  initial: I;
}>;

export type UnmountVariantLabelProps<
  I extends string,
  A extends string,
  E extends string
> = Expand<{
  animate: A;
  exit: E;
  initial: I;
}>;

export type BaseVariantLabelProps = {
  animate?: string;
  exit?: string;
  initial?: string;
};

/**
 * Creates properly typed object  variant labels to use as props on a motion
 * components `animate`, `initial` and `exit` props.
 */

export function makeVariantLabelProps<A extends string>(animate: A): SimpleVariantLabelProps<A>;
export function makeVariantLabelProps<I extends string, A extends string>(
  initial: I,
  animate: A
): UnmountVariantLabelProps<I, A, I>;
export function makeVariantLabelProps<I extends string, A extends string>(
  initial: I,
  animate: A,
  exit: false
): MountVariantLabelProps<I, A>;
export function makeVariantLabelProps<I extends string, A extends string, E extends string>(
  initial: I,
  animate: A,
  exit: E
): UnmountVariantLabelProps<I, A, E>;
export function makeVariantLabelProps<I extends string, A extends string, E extends string>(
  initialOrAnimate: I,
  animate?: A,
  exit?: E | false
): SimpleVariantLabelProps<I> | MountVariantLabelProps<I, A> | UnmountVariantLabelProps<I, A, E> {
  if (typeof animate === 'undefined') {
    return { animate: initialOrAnimate } as SimpleVariantLabelProps<I>;
  }

  const initial = initialOrAnimate;

  if (exit === false) {
    return { animate, initial } as MountVariantLabelProps<I, A>;
  }

  if (typeof exit === 'undefined')
    return { animate, exit: initial, initial } as UnmountVariantLabelProps<I, A, I>;

  return { animate, exit, initial };
}

export function makeMotionProps<
  VL extends BaseVariantLabelProps,
  // @@HACK Ignoring typescript here as the type actually works, but it does
  // not compile
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  V extends { [K in VL[keyof VL]]: Variant }
>(variantLabels: VL, variants: V): VL & { variants: V } {
  return {
    ...variantLabels,
    variants,
  };
}
