import Box, { BoxProps } from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface TimepointProps extends BoxProps {
  sx?: BoxProps['sx'];
  year: number;
}

export function Timepoint({ sx, year }: TimepointProps) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: { md: 2, xs: 1 },
        ...sx,
      }}
    >
      <Box
        sx={{
          border: '3px solid white',
          borderRadius: '50%',
          height: '20px',
          position: 'relative',
          width: '20px',
        }}
      />
      <Typography variant="h5">{year}</Typography>
    </Box>
  );
}

export interface TimelineProps {
  year1: number;
  year1Text: string;
  year2: number;
  year2Text: string;
}

export function Timeline({ year1, year1Text, year2, year2Text }: TimelineProps) {
  return (
    <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
      <Grid container>
        <Grid item md={12} sx={{ order: { md: 1, xs: 2 } }} xs={1}>
          <Box
            sx={{
              alignItems: { md: 'flex-start', xs: 'center' },
              display: 'flex',
              flexDirection: { md: 'row', xs: 'column' },
              height: '100%',
              width: '100%',
            }}
          >
            <Timepoint
              sx={{
                flexDirection: { md: 'column', xs: 'column-reverse' },
                marginLeft: { md: '20%', xs: 0 },
                // marginLeft: ({ spacing }) => ({ md: spacing(10), xs: 0 }),
              }}
              year={year1}
            />

            <Box
              sx={{
                borderBottom: '6px dotted white',
                borderLeft: '6px dotted white',
                flex: 1,
                mb: { md: 0, xs: 2 },
                mt: { md: '7px', xs: 2 },
                mx: { md: 2, xs: 0 },
              }}
            />

            <Timepoint
              sx={{
                marginRight: { md: '20%', xs: 0 },
                // border: '2px solid red',
                // marginRight: ({ spacing }) => ({ md: spacing(10), xs: 0 }),
              }}
              year={year2}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            gap: 3,
            order: { md: 2, xs: 1 },
            pr: { md: 0, xs: 3 },
            pt: { md: 3, xs: 0 },
          }}
          xs={11}
        >
          <Box flex={1}>
            <Typography component="p" variant="h5">
              {year1Text}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography component="p" variant="h5">
              {year2Text}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
