/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react';

export function useBoolean(defaultState = false) {
  const [state, setState] = useState(defaultState);

  // Define and memorize toggler functions in case we pass down the comopnent:
  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggleState = useCallback(() => setState((s) => !s), []);

  return {
    setFalse,
    setState,
    setTrue,
    state,
    toggleState,
  };
}
