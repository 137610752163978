import { Button, Paper } from '@mui/material';
import { useBoolean } from '../utils/hooks/useBoolean';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import Typography from '@mui/material/Typography';
import create from 'zustand';

interface Store {
  accept: () => void;
  accepted: boolean;
}

const useStore = create<Store>((set) => ({
  accept: () => set(() => ({ accepted: true })),
  accepted: false,
}));

export interface EmbeddedYoutubeVideoProps {
  placeholder?: string;
  src: string;
  title: string;
  variant?: 'small' | 'default';
}

interface EmbeddedYoutubeVideoPermissionRequestProps {
  onAccept: () => void;
}

function EmbeddedYoutubeVideoPermissionRequest({
  onAccept,
}: EmbeddedYoutubeVideoPermissionRequestProps) {
  const { state: checked, toggleState: toggleChecked } = useBoolean(true);

  const accept = useStore((state) => state.accept);

  const submitCookiePreference = () => {
    if (checked) accept();
    onAccept();
  };

  return (
    <Paper
      elevation={2}
      sx={({ spacing }) => ({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(2),
        height: '100%',
        justifyContent: 'center',
        left: 0,
        p: spacing(3),
        position: 'absolute',
        top: 0,
        width: '100%',

        // bottom: spacing(2),
        // left: spacing(2),
        // right: spacing(2),
        // top: spacing(2),
      })}
    >
      <Typography gutterBottom>
        Deze content wordt gehost door een third-party (Youtube). Bij het tonen van deze content
        gaat u akkoord met de{' '}
        <a href="https://www.youtube.com/static?template=terms" rel="noreferrer" target="_blank">
          voorwaarden
        </a>{' '}
        van Youtube.
      </Typography>

      <Button onClick={submitCookiePreference} variant="contained">
        Toon externe content
      </Button>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={checked} name="gilad" onChange={toggleChecked} />}
          label="Onthoud mijn keuze*"
        />
        <FormHelperText>*Uw keuze blijft actief tot u het tabblad sluit.</FormHelperText>
      </Box>
    </Paper>
  );
}

interface EmbeddedYoutubeVideoPermissionRequestProps2 {
  onAccept: () => void;
  placeholder?: string;
  variant?: 'default' | 'small';
}

function EmbeddedYoutubeVideoPermissionRequest2({
  onAccept,
  placeholder,
  variant = 'default',
}: EmbeddedYoutubeVideoPermissionRequestProps2) {
  const { state: checked, toggleState: toggleChecked } = useBoolean(true);

  const accept = useStore((state) => state.accept);

  const submitCookiePreference = () => {
    if (checked) accept();
    onAccept();
  };

  const isSmall = variant === 'small';

  return (
    <Paper
      elevation={5}
      sx={({ spacing }) => ({
        alignItems: 'center',
        backgroundImage: `url('${placeholder}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxShadow: `inset 0 0 0 50vw rgba(255,255,255,0.65)`,
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(2),
        height: '100%',
        justifyContent: 'center',
        left: 0,
        p: spacing(3),
        position: 'absolute',
        top: 0,
        width: '100%',
      })}
    >
      <Box
        sx={{
          '&:hover': {
            transform: 'scale(1.05)',
          },
          transition: 'transform 0.1s ease-out',
        }}
      >
        <SmartDisplayIcon
          color="primary"
          onClick={submitCookiePreference}
          sx={{ cursor: 'pointer', fontSize: isSmall ? '120px' : '240px' }}
        />
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          boxShadow: ({ palette }) => `inset 0 0 0 50vw ${palette.primary.main}99`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: isSmall ? 1 : 2,
          position: 'absolute',
          top: 0,
          width: 1,
        }}
      >
        <Typography align="center" variant={isSmall ? 'subtitle2' : 'body1'}>
          Deze content wordt gehost door een third-party (Youtube). Bij het tonen van deze content
          gaat u akkoord met de{' '}
          <a
            href="https://www.youtube.com/static?template=terms"
            rel="noreferrer"
            style={{ color: 'white' }}
            target="_blank"
          >
            voorwaarden
          </a>{' '}
          van Youtube.
        </Typography>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={checked} color="secondary" onChange={toggleChecked} />}
            label={
              <Typography variant={isSmall ? 'subtitle2' : 'body1'}>
                Onthoud mijn keuze tot ik het tabblad sluit.
              </Typography>
            }
          />
        </Box>
      </Box>
    </Paper>
  );
}

function EmbeddedYoutubeVideoIframe({ src, title }: EmbeddedYoutubeVideoProps) {
  return (
    <Box
      allowFullScreen
      component="iframe"
      frameBorder="0"
      // height="315"
      src={src}
      sx={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      }}
      title={title}
      // width="560"
    />
  );
}

export function EmbeddedYoutubeVideo({
  placeholder,
  src,
  title,
  variant,
}: EmbeddedYoutubeVideoProps) {
  const [accepted, setAccepted] = useState(false);

  const storedAccepted = useStore((state) => state.accepted);

  const handleAcceptPermissionRequest = () => {
    setAccepted(true);
  };

  return (
    <Paper
      elevation={5}
      sx={{
        height: 0,
        paddingBottom: '56.25%',
        position: 'relative',
        width: 1,
      }}
    >
      {storedAccepted || accepted ? (
        <EmbeddedYoutubeVideoIframe src={src} title={title} />
      ) : (
        <EmbeddedYoutubeVideoPermissionRequest2
          onAccept={handleAcceptPermissionRequest}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    </Paper>
  );
}
