import { FormikErrors, useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

const defaultShouldTriggerErrors = (
  errors: FormikErrors<unknown>,
  nextErrors: FormikErrors<unknown>
) => !isEqual(errors, nextErrors);

export interface FormikErrorListenerProps {
  onError: (errors: FormikErrors<unknown>) => void;
  shouldTriggerErrors?: (
    errors: FormikErrors<unknown>,
    nextErrors: FormikErrors<unknown>
  ) => boolean;
}

export function FormikErrorListener({
  onError,
  shouldTriggerErrors = defaultShouldTriggerErrors,
}: FormikErrorListenerProps) {
  const formik = useFormikContext();
  const [errors, updateErrors] = useState(formik.errors);

  useEffect(() => {
    if (formik.isSubmitting && shouldTriggerErrors(errors, formik.errors)) {
      onError(formik.errors);

      updateErrors(errors);
    }
  }, [formik.errors, formik.isSubmitting]);

  return null;
}
