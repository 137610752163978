import { ReactElement } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { NEWS_ROUTE } from '../../../../../routing/ROUTES';
import { PATHS } from '../../PATHS';
import { useLocation } from 'react-router-dom';
import ContactCard from './components/ContactCard';
import NavTile from './components/NavTile';
// import fotoNus from '../../../../../assets/images/foto-nus.jpg';
import fotoMarieClaire from '../../../../../assets/images/foto-marie-claire.png';
import fotoRene from '../../../../../assets/images/foto-rene.jpg';

export default function Footer(): ReactElement {
  const { pathname } = useLocation();
  const showNavTiles = pathname !== NEWS_ROUTE;

  return (
    <>
      {showNavTiles && (
        <Container sx={{ paddingBottom: { sm: 10, xs: 4 }, paddingTop: { sm: 5, xs: 3 } }}>
          <Typography
            color="primary"
            gutterBottom
            sx={{
              paddingBottom: { sm: 3, xs: 1 },
              paddingTop: { sm: 5, xs: 1 },
            }}
            variant="h4"
          >
            Meer weten over:
          </Typography>
          <Grid container spacing={3}>
            {PATHS.map(({ id, image, title, to }) => (
              <NavTile key={id} image={image} title={title} to={to} />
            ))}
          </Grid>
        </Container>
      )}
      <AppBar
        component="footer"
        elevation={0}
        sx={{
          color: '#fff',
          display: 'flex',
          position: 'static',
          pt: 5,
          top: 'unset',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Container disableGutters maxWidth={false}>
            <Container maxWidth="lg" sx={{ pb: 5 }}>
              <Box mb={4}>
                <Typography
                  color="inherit"
                  gutterBottom
                  sx={{
                    paddingTop: { sm: undefined, xs: 0 },
                    textAlign: { sm: 'left', xs: 'center' },
                  }}
                  variant="h4"
                >
                  Wilt u meer informatie?
                </Typography>
                <Typography
                  color="inherit"
                  gutterBottom
                  sx={{ textAlign: { sm: 'left', xs: 'center' } }}
                  variant="h6"
                >
                  Neem dan contact op met een van onderstaande personen, wij staan u graag te woord.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'row', xs: 'column' },
                  gap: { sm: 4, xs: 3 },
                }}
              >
                <ContactCard
                  emailAdress={{
                    display: 'r.rummens@bdok.nl',
                    mailto: 'r.rummens@bdok.nl',
                  }}
                  fullName="René Rummens"
                  imageSrc={fotoRene}
                  phoneNumber={{
                    display: '+31 6 20 07 83 57',
                    tel: '0031620078357',
                  }}
                />
                <ContactCard
                  emailAdress={{
                    display: 'm.eichhorn@bdok.nl',
                    mailto: 'm.eichhorn@bdok.nl',
                  }}
                  fullName="Marie-Claire Eichhorn"
                  imageSrc={fotoMarieClaire}
                  phoneNumber={{
                    display: '+31 30 711 4704',
                    tel: '0031307114704',
                  }}
                />
              </Box>
            </Container>
            <Box
              sx={{
                backgroundColor: ({ palette }) => palette.secondary.main,

                clipPath: ({ spacing }) => ({
                  sm: `polygon(${spacing(1.75)} 0, 100% 0, 100% 100%, 0 100%)`,
                  xs: `polygon(${spacing(1)} 0, 100% 0, 100% 100%, 0 100%)`,
                }),
                height: ({ spacing }) => ({ sm: spacing(1.75), xs: spacing(1) }),
                marginLeft: 'auto',
                width: 0.75,
              }}
            />
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
}
