import { Field, Form, FormikProps } from 'formik';
import { ReactElement } from 'react';
import { TextField } from 'formik-mui';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { FormValues } from '../formik';
import privacyStatement from '../../../assets/documents/20220714-BDOK Privacy Verklaring.pdf';

export default function RequestDemoPresentationForm({
  isSubmitting,
}: FormikProps<FormValues>): ReactElement {
  const { typography } = useTheme();

  return (
    <Form style={{ display: 'flex', flexDirection: 'column' }}>
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Voornaam*"
        margin="dense"
        name="firstName"
      />

      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Tussenvoegsel"
        margin="dense"
        name="insertion"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Achternaam*"
        margin="dense"
        name="lastName"
      />

      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Bedrijfsnaam*"
        margin="dense"
        name="company"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="E-mailadres*"
        margin="dense"
        name="emailAddress"
      />
      <Field
        component={TextField}
        fullWidth
        InputLabelProps={{ style: { fontSize: typography.body2.fontSize } }}
        InputProps={{ style: { fontSize: typography.body2.fontSize } }}
        label="Telefoonnummer*"
        margin="dense"
        name="phoneNumber"
      />

      <Button
        disabled={isSubmitting}
        size="large"
        sx={{ borderRadius: '21.125px', ml: 'auto', mt: ({ spacing }) => spacing(5) }}
        type="submit"
        variant="contained"
      >
        Demo aanvragen
      </Button>

      <Typography gutterBottom sx={{ my: 3 }} textAlign="end" variant="caption">
        Velden met een * zijn verplicht. Benieuwd hoe wij met jouw gegevens omgaan? Dit lees je in
        onze{' '}
        <Box component="a" href={privacyStatement} rel="noopener noreferrer" target="_blank">
          privacyverklaring
        </Box>
        .
      </Typography>
    </Form>
  );
}
