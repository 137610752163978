import { NewsArticle, NewsArticleCommons } from './news-article';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import image1 from './1920xauto_milieu-veiligheid-bdok-kaart-antea-group.png';
import image3 from './1920xauto_milieu-veiligheid-bdok-tool-antea-group.png';
import image4 from './foto-rene-hr.jpg';

export function NewsArticleOne() {
  return (
    <NewsArticleCommons
      categories={['bodem', 'graafwerk']}
      date={new Date(2022, 9, 1, 12, 0)}
      imageAuthor="Antea Group"
      side="right"
      src={image1}
      textAuthor="Chris Jellema"
      title="GWW Totaal: Bodemdata altijd binnen handbereik met BDOK"
    >
      <NewsArticle.Introduction>
        In de grond-, weg- en waterbouwsector vinden in vrijwel elk project werkzaamheden onder het
        maaiveld plaats. Zodra er gegraven wordt, geldt vanuit de Arbeidsomstandighedenwet en de Wet
        Bodembescherming de verplichting om vooraf te weten hoe het gesteld is met de bodemkwaliteit
        ter plaatse. Het is van belang om in een vroegtijdig stadium eventuele bodemverontreiniging
        en daarmee risico’s in beeld te krijgen en beheersbaar te maken. Ingenieurs- en adviesbureau
        Antea Group ontwikkelde in samenwerking met Nazca Solutions een applicatie waarmee inzicht
        in bodemkwaliteit en veiligheidsrisico’s niet langer ten koste hoeft te gaan van de
        voortgang van het werk: Bodem Digitaal Op de Kaart, kortweg BDOK.
      </NewsArticle.Introduction>

      <Typography component="p" paragraph variant="caption">
        Dit artikel verscheen in de GWW Totaal.
      </Typography>

      <NewsArticle.Image src={image1} />

      <NewsArticle.Text>
        Van monteurs en grondwerkers tot engineers en werkvoorbereiders: dagelijks werken duizenden
        professionals aan projecten die de ondergrond raken. Of het nu gaat om een storing,
        onderhoud of de realisatie van een werk: overal waar de schop de grond ingaat, is onderzoek
        nodig om medewerkers te beschermen tegen de risico’s van bodemverontreiniging. “Het
        verkrijgen van bodeminformatie heeft veel voeten in de aarde. Het raadplegen van bronnen en
        het opvragen van bodemdossiers zoals bodemkwaliteitskaarten, eerder uitgevoerde
        bodemonderzoeken en saneringen, eventuele ondergrondse tanks en het historisch bodembestand
        is tijdrovend,” vertelt strategisch milieuadviseur en BDOK-productmanager René Rummens van
        Antea Group. “Het duurt vaak één of meerdere werkdagen voordat je alle informatie hebt
        verzameld en hebt kunnen beoordelen alvorens je weet wanneer je kunt graven, welke
        voorzorgsmaatregelen je moet nemen en of aanvullend bodemonderzoek nodig is. Dat is niet
        efficiënt en wij dachten dat dit beter kan.”
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Bodem Digitaal Op de Kaart</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Omdat Antea Group al zeventig jaar bestaat, was er binnen de organisatie enorm veel
        (historische) bodeminformatie uit heel Nederland aanwezig en wist men bovendien goed de weg
        naar andere datasets uit gevalideerde overheidsbronnen te vinden. Nu was de uitdaging om al
        deze kennis bij elkaar te krijgen en digitaal toegankelijk te maken. In 2015 werd daarom een
        samenwerking aangegaan met Nazca Solutions en daaruit werd Bodem Digitaal Op de Kaart
        geboren. Rummens: “Met BDOK brengen we op twee manieren efficiëntie in het proces.
        Allereerst zorgen we ervoor dat we de relevante bodemdata voor een locatie bij elkaar
        brengen en voortdurend actualiseren en uitbreiden met nieuwe categorieën. Daarnaast hebben
        we een belangrijk deel van het vooronderzoek, zoals dit beschreven wordt in de CROW
        400-richtlijn, geautomatiseerd in BDOK. Op die manier kan een aanvraag veel sneller én
        goedkoper worden verwerkt.”
      </NewsArticle.Text>

      <NewsArticle.Image src={image3} />

      <NewsArticle.Subtitle>Knelpunten en kansen</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Waar Nazca Solutions de IT-expertise inbrengt en zorg draagt voor het actueel houden van de
        data, staat Antea Group garant voor de benodigde domeinkennis en het contact met de klant.
        Met meer dan een halve eeuw ervaring op het gebied van grondroeren en het uitvoeren en
        begeleiden van bodemonderzoek en -sanering, weet het ingenieurs- en adviesbureau immers als
        geen ander waar in de praktijk de knelpunten en kansen liggen in de werkprocessen. “Met BDOK
        bieden we inzicht, overzicht en precies de juiste informatie. Hiervoor verzamelen we alle
        beschikbare bodemdata die we vervolgens vertalen naar uiteenlopende toepassingen, zoals een
        storingskaart, een quickscan, huisaansluitingenmodule en een historisch vooronderzoek,” somt
        Rummens op. Deze producten zijn online beschikbaar om de werkprocessen voor aannemers,
        netbeheerders en adviesbureaus te optimaliseren.
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Direct inzicht, ook in het veld</NewsArticle.Subtitle>

      <NewsArticle.Text>
        In zeven jaar tijd is BDOK uitgegroeid tot een applicatie met een brede groep gebruikers,
        van Rijkswaterstaat en gemeenten tot netbeheerders en aannemers. Medewerkers van deze
        organisaties kunnen 24 uur per dag en zeven dagen per week inloggen op het systeem. Dat kan
        vanaf een desktop of laptop maar ook in het veld met een tablet of smartphone. “Op de BDOK
        Storingskaart zien monteurs in één oogopslag of ze ergens veilig aan het werk kunnen. Met
        een BDOK Quickscan of uitgebreide BDOK Combirapportage beschikken werkvoorbereiders en
        engineers al in de planfase over alle bodemrisico’s. Zo weet iedereen razendsnel wat ze
        precies kunnen verwachten,” legt Rummens uit.
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Samenwerken en software koppelen</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Jaarlijks vinden er in ons land meer dan 700.000 graafactiviteiten plaats waarbij informatie
        over de bodem noodzakelijk is. Vaak worden daarbij onnodige bodemonderzoeken uitgevoerd om
        CROW 400-compliant te zijn. Volgens Rummens kan door hergebruik van bodemdata veel onnodig
        bodemonderzoek worden vermeden: “Om die reden zetten wij met BDOK sterk in op samenwerking.
        Zo is het bijvoorbeeld mogelijk om BDOK te implementeren in software van derden. Onlangs
        zijn we op die manier een samenwerking aangegaan met een partij die marktleider is op het
        gebied van innovatieve oplossingen in het domein van kabels en leidingen. Zij hebben BDOK
        aan hun GIS-platform gekoppeld, waardoor hun opdrachtgevers in een vroeg stadium van een
        project al kunnen beschikken over alle relevante ondergrondse gebiedsdata.”
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Ontwikkelen in de breedte én diepte</NewsArticle.Subtitle>

      <NewsArticle.Text display="flex" sx={{ flexDirection: { md: 'row', xs: 'column' }, gap: 4 }}>
        <span>
          Als het aan René Rummens ligt, ontwikkelt BDOK zich de komende jaren overigens niet alleen
          in de breedte – door samenwerking met andere partijen – maar ook in de diepte, waar het de
          database van bodeminformatie betreft. Binnen een ingenieurs- en adviesbureau waar veel
          verschillende disciplines en werkzaamheden te vinden zijn, wordt Rummens voortdurend
          geïnspireerd om weer nieuwe toepassingen aan BDOK toe te voegen. “Bij Antea Group hebben
          we bijvoorbeeld ook archeologen rondlopen. In Nederland hebben we de zorgplicht voor ons
          erfgoed vastgelegd in de Erfgoedwet. Dit betekent dat je bij de voorbereiding en
          uitvoering van infrawerken, bouwplannen of grond acquisities al vroegtijdig rekening moet
          houden met archeologie,” vertelt hij. Dat resulteerde al snel in de vraag of BDOK, met
          zijn bewezen waarde bij vooronderzoek naar bodemkwaliteit en mogelijke
          veiligheidsrisico’s, wellicht ook gebruikt zou kunnen worden om snel te weten te komen of
          archeologie een rol speelt bij bouw- en inrichtingsplannen, infrawerk of een grondverkoop
          in de voorbereiding?
        </span>

        <Box
          component="img"
          src={image4}
          sx={{
            objectFit: 'cover',
            objectPosition: ({ spacing }) => ({
              md: `right ${spacing(-12)} top 0px`,
              xs: 'center',
            }),
            width: { md: '25%', xs: '100%' },
          }}
        />
      </NewsArticle.Text>

      <NewsArticle.Subtitle>Archeologie, oorlogsresten of ecologie</NewsArticle.Subtitle>

      <NewsArticle.Text>
        Dergelijke vragen zijn aan Rummens goed besteed: “Inmiddels zijn we in dezelfde lijn als
        BDOK druk bezig met de ontwikkeling van Archeologie Digitaal Op de Kaart (ADOK) en datzelfde
        doen we ook voor de aanwezigheid van ontplofbare oorlogsresten (OODOK), waarvan we er in
        Nederland nog heel wat in de grond hebben zitten,” weet Rummens. Ook wordt er gewerkt aan
        een ecologische toepassing (EDOK) om betrokken partijen te helpen om op een digitale en
        snelle manier compliant te zijn wanneer de schep de grond in moet. “Samen met onze
        opdrachtgevers maken we bij Antea Group de wereld elke dag een stukje mooier. Een platform
        als BDOK draagt hier zeker aan bij,” besluit Rummens.
      </NewsArticle.Text>
    </NewsArticleCommons>
  );
}
