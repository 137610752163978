import { SVGProps, forwardRef } from 'react';

interface LogoComponentProps extends SVGProps<SVGSVGElement> {
  /**
   * Changes the logo to a single color.
   *
   * Any valid css color
   */
  singleColor?: string;
}
const NazcaSolutionsLogo = forwardRef<SVGSVGElement, LogoComponentProps>(
  ({ singleColor, ...props }, ref) => (
    <svg
      ref={ref}
      viewBox="0 0 698.471 212.116"
      x={0}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <style>transition: all 0.3s ease;</style>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M0 360.609h702.556V0H0Z" />
        </clipPath>
      </defs>
      <path
        d="M0 0v-14.983h-52.615V-.581l28.456 30.315h-28.34v14.983h50.757V30.314L-30.547 0Z"
        style={{
          fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="matrix(1.33333 0 0 -1.33333 273.64 61.487)"
      />
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 -117.648 349.467)">
        <path
          d="M0 0c-10.686 0-21.372 4.53-27.76 12.079l9.757 11.5c4.762-6.156 13.124-9.059 19.281-9.059 4.297 0 6.969 1.276 6.969 3.948 0 2.904-2.556 4.065-9.293 5.226-9.408 1.743-22.881 4.414-22.881 18.701 0 13.008 11.034 19.396 23.114 19.396 8.479 0 16.958-2.903 22.649-8.478l-8.48-10.918c-4.064 3.833-9.872 5.342-14.169 5.342-3.833 0-6.505-1.393-6.505-4.529s2.788-3.949 10.454-5.343c9.407-1.743 21.836-4.065 21.836-17.539C24.972 7.201 13.241 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(115.996 105.138)"
        />
        <path
          d="M0 0c0-9.407 5.575-15.912 14.286-15.912 8.711 0 14.287 6.505 14.287 15.912 0 9.408-5.576 15.912-14.287 15.912C5.575 15.912 0 9.408 0 0m45.414 0c0-17.654-13.241-30.896-31.128-30.896S-16.726-17.654-16.726 0c0 17.655 13.125 30.896 31.012 30.896S45.414 17.655 45.414 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(165.009 136.033)"
        />
        <path
          d="M0 0c0-5.807-4.762-10.453-10.918-10.453-6.155 0-10.918 4.646-10.918 10.453 0 5.808 4.763 10.454 10.918 10.454C-4.762 10.454 0 5.808 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(401.685 182.493)"
        />
        <path
          d="M0 0c-10.687 0-21.372 4.53-27.76 12.079l9.757 11.5c4.762-6.156 13.124-9.059 19.281-9.059 4.297 0 6.969 1.276 6.969 3.948 0 2.904-2.556 4.065-9.293 5.226-9.407 1.743-22.881 4.414-22.881 18.701 0 13.008 11.034 19.396 23.115 19.396 8.478 0 16.957-2.903 22.648-8.478l-8.48-10.918c-4.064 3.833-9.872 5.342-14.168 5.342-3.834 0-6.505-1.393-6.505-4.529s2.788-3.949 10.453-5.343c9.408-1.743 21.836-4.065 21.836-17.539C24.972 7.201 13.241 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(587.118 105.138)"
        />
        <path
          d="M0 0c0 9.175 6.04 13.473 11.847 13.473 5.343 0 10.105-3.369 10.105-10.569V-2.81h16.725v8.037c0 15.796-9.64 23.113-21.255 23.113-7.549 0-14.286-3.136-17.887-8.595v7.201h-16.261V-2.81H0Z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(109.028 233.756)"
        />
        <path
          d="M0 0c0 9.292 5.226 15.68 14.054 15.68 5.342 0 9.524-2.206 12.66-7.084l11.963 9.756c-5.574 8.13-14.286 12.312-24.739 12.312-18.087 0-30.618-13.426-30.662-31.15H.009C.006-.324 0-.164 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(317.276 231.432)"
        />
        <path
          d="M109.028 230.946H92.302v-29.944h16.726zM130.979 201.002h16.726v29.944h-16.726z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#952f45',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M0 0c11.267 0 20.907 5.808 26.831 15.564l-11.151 8.13c-3.252-5.458-8.826-8.711-14.169-8.711-8.787 0-14.766 6.616-14.974 15.658h-16.733c0-.031-.003-.062-.003-.094C-30.199 13.241-19.164 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#952f45',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(330.75 200.305)"
        />
        <path
          d="M238.411 187.023h-16.725v-51.504h16.725z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#ed8b00',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M0 0c0 9.175 6.039 13.473 11.847 13.473 5.342 0 10.104-3.369 10.104-10.57V-3.07h16.726v8.297c0 15.795-9.641 23.113-21.255 23.113-7.55 0-14.286-3.136-17.887-8.595v7.2h-16.262V-3.07H0Z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#ed8b00',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(509.882 138.603)"
        />
        <path
          d="M0 0c4.414 0 9.06 1.278 13.706 3.717l-3.95 12.892c-1.858-1.045-3.833-1.626-5.574-1.626-3.95 0-6.97 2.788-6.97 7.55v7.848h-16.725v-9.59C-19.513 7.201-10.802 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(241.2 105.138)"
        />
        <path
          d="M531.833 105.835h16.726v29.697h-16.726zM509.882 135.532h-16.727v-29.697h16.727z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <path
          d="M0 0c-2.787-1.859-5.924-2.788-8.595-2.788-5.11 0-9.059 3.019-9.059 9.292v21.14H-.58v14.982h-17.074v14.635h-16.842V42.626H-70.849V10.569c0-9.175-5.459-13.473-11.15-13.473-5.459 0-9.524 3.369-9.524 10.685v34.845h-16.725V5.343c0-15.681 9.06-23.114 20.558-23.114 6.842 0 14.054 3.787 18.028 8.56 1.297-6.841 11.684-10.685 11.684-10.685l10.315 12.894s-5.635 1.617-6.46 11.372v18.247l.033 5.027h19.594V3.601c0-13.823 9.873-21.372 21.603-21.372 5.577 0 11.499 1.742 17.075 5.343z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(370.468 122.91)"
        />
        <path
          d="M0 0c-8.71 0-14.634 6.504-14.634 16.029 0 9.176 5.574 16.144 14.518 16.144s14.983-6.62 14.983-16.144C14.867 6.504 8.828 0 0 0m31.693 10.205.235 35.632H15.139l.001-.072h-.043l.142-7.969c-2.947 5.595-9.522 9.128-18.607 9.128-17.771 0-27.992-13.705-27.992-30.895 0-17.423 10.802-30.896 27.992-30.896 8.597 0 15.516 3.247 19.635 8.972 1.294-6.784 10.347-10.155 10.347-10.155L38.257-3.625s-6.564 1.875-6.564 13.83"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(189.842 215.177)"
        />
        <path
          d="M0 0c-8.71 0-14.634 6.504-14.634 16.029 0 9.176 5.574 16.144 14.519 16.144 8.943 0 14.982-6.62 14.982-16.144C14.867 6.504 8.828 0 0 0m31.694 10.205.235 35.632H15.14v-.072h-.042s.055-3.186.142-7.969c-2.947 5.595-9.522 9.128-18.607 9.128-17.771 0-27.992-13.705-27.992-30.895 0-17.423 10.801-30.896 27.992-30.896 8.597 0 15.518 3.247 19.637 8.974 1.344-6.804 11.673-10.626 11.673-10.626L38.258-3.625s-6.564 1.875-6.564 13.83"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(394.49 215.177)"
        />
        <path
          d="m0 0-.036.002L-.079 0Z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(409.436 120.132)"
        />
        <path
          d="m0 0-.001-30.392h17.218L17.254 0Z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(381.6 165.908)"
        />
        <path
          d="m0 0-.036.002L-.079 0Z"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(409.436 120.148)"
        />
        <path
          d="m0 0 .036-.002h-.079L0 0c-10.493 0-10.697 14.369-10.698 15.367h-17.218c.02-17.048 9.751-30.18 27.817-30.274v-.007h.198v.007c18.207.095 27.491 13.328 27.516 30.29H10.664C10.663 15.383 10.643 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#952f45',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(409.515 120.15)"
        />
        <path
          d="M0 0c-17.348 0-30.005-10.205-30.792-31.413h16.949s.02 1.098.014 2.099h.007c.236 8.67 5.476 14.331 13.822 14.331 8.711 0 14.286-6.505 14.286-15.913 0-9.408-5.575-15.913-14.286-15.913-3.771 0-6.943 1.228-9.338 3.376-1.146-7.189-3.794-11.71-6.268-14.472 4.512-2.486 9.805-3.886 15.606-3.886 17.887 0 31.128 13.24 31.128 30.895S17.887 0 0 0"
          style={{
            fill: typeof singleColor === 'string' ? singleColor : '#00b2d5',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
          transform="translate(450.973 166.946)"
        />
      </g>
    </svg>
  )
);
export default NazcaSolutionsLogo;
