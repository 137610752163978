import { ReactElement } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface Props {
  emailAdress: {
    display: string;
    mailto: string;
  };
  fullName: string;
  imageSrc: string;
  phoneNumber: {
    display: string;
    tel: string;
  };
}

export default function ContactCard({
  emailAdress,
  fullName,
  imageSrc,
  phoneNumber,
}: Props): ReactElement {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: { sm: 'row', xs: 'column' },
        gap: { sm: 5, xs: 2 },
      }}
    >
      <Avatar
        src={imageSrc}
        sx={{ height: { sm: '150px', xs: '125px' }, width: { sm: '150px', xs: '125px' } }}
      />

      <Box>
        <Typography
          gutterBottom
          sx={{ color: 'inherit', textAlign: { sm: 'left', xs: 'center' } }}
          variant="h6"
        >
          {fullName}
        </Typography>

        <Typography sx={{ color: 'inherit', textAlign: { sm: 'left', xs: 'center' } }}>
          Bel naar{' '}
          <Link href={`tel:${phoneNumber.tel}`} style={{ color: 'inherit' }}>
            {phoneNumber.display}
          </Link>
          <br />
          of mail naar{' '}
          <Link href={`mailto:${emailAdress.mailto}`} style={{ color: 'inherit' }}>
            {emailAdress.display}.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
