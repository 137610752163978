import { EmbeddedYoutubeVideo } from '../../../components/EmbeddedYoutubeVideo';
import { FormikErrorListener } from '../../../components/formik-error-listener';
import { ReactElement } from 'react';
import { isIOS } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/Forward';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import HERO_IMAGE_1 from '../../../assets/images/0006-2022-03-15 Antea Group-Bergen op Zoom asbestsanering.jpg';
import HERO_IMAGE_2 from '../../../assets/images/0018-2022-03-15 Antea Group-Bergen op Zoom asbestsanering.jpg';
import HERO_IMAGE_3 from '../../../assets/images/0011-2021-12-17 Projectshoot Antea Group - TenneT-min.jpg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalVideoPlayer from '../../../components/LocalVideoPlayer';
import Paper from '@mui/material/Paper';
import RequestDemoPresentationForm from '../../../features/request-demo-presentation/form';
import RequestDemoPresentationFormik from '../../../features/request-demo-presentation/formik';
import Typography from '@mui/material/Typography';
import bdok_bulkbevraging_poster from '../../../assets/video/Thumbnail_Bulkbevraging.png';
import bdok_bulkbevraging_video from '../../../assets/video/BDOK_Bulkbevraging.mp4';
import bdok_quickscan_poster from '../../../assets/video/Thumbnail_Quickscan.png';
import bdok_quickscan_video from '../../../assets/video/BDOK_Quickscan.mp4';

export default function WhatIsBDOKView(): ReactElement {
  const { spacing } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Container disableGutters maxWidth={false}>
      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) ${spacing(
            40
          )}), url('${HERO_IMAGE_1}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          height: '40vh',
          justifyContent: 'center',
          minHeight: '200px',
          position: 'relative',
          width: '100%',
        }}
      >
        <Container
          color="white"
          sx={{
            bottom: '50%',
            position: 'absolute',
            transform: {
              md: `translateY(calc(50% + (${spacing(17)} / 2)))`,
              xs: `translateY(calc(50% + (${spacing(10)} / 2)))`,
            },
          }}
        >
          <Typography color="white" sx={{ fontWeight: '700' }} variant="h2">
            Wat is BDOK
          </Typography>
        </Container>
      </Box>
      <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {/*  order={{ md: 1, xs: 2 }} */}
            <Typography color="primary" sx={{ fontStyle: 'italic' }} variant="h3">
              Versnelt het vooronderzoek bij het werken in en met verontreinigde bodem.
            </Typography>
          </Grid>
          <Grid item sx={{ alignItems: 'center', display: 'flex' }} xs={12}>
            <Typography color="primary" variant="h4">
              Van monteurs en grondwerkers tot engineers en werkvoorbereiders: dagelijks werken
              duizenden professionals aan projecten die de ondergrond raken. Of het nu gaat om een
              storing, onderhoud of de realisatie van een werk: overal waar de schop de grond
              ingaat, is onderzoek nodig om medewerkers te beschermen tegen de risico&apos;s van
              bodemverontreiniging.
            </Typography>
          </Grid>
          <Grid
            item
            // md={6}
            order={{ md: 2, xs: 1 }}
            sx={{ alignItems: 'center', display: 'flex' }}
            xs={12}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: spacing(4),
                py: spacing(4),
              }}
            >
              <Divider sx={{ width: spacing(20) }} />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: spacing(2),
                }}
              >
                <Typography
                  color="secondary"
                  component="q"
                  gutterBottom
                  sx={{
                    fontStyle: 'italic',
                    px: spacing(8),
                  }}
                  variant="h3"
                >
                  Met BDOK weet je precies wanneer je kunt graven, welke voorzorgsmaatregelen moeten
                  worden genomen en of aanvullend bodemonderzoek nodig is.
                </Typography>
                {/* <Typography variant="button">Jacques-Yves Cousteau</Typography> */}
              </Box>
              <Divider sx={{ width: spacing(20) }} />
            </Box>
          </Grid>
          <Grid
            item
            // md={6}
            order={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            xs={12}
          >
            <Typography color="primary" variant="h4">
              Het verkrijgen van bodeminformatie heeft veel voeten in de aarde. Het raadplegen en
              beoordelen van bronnen zoals bodemkwaliteitskaarten, eerder uitgevoerde
              bodemonderzoeken en saneringen, ondergrondse tanks en het historisch bodembestand is
              tijdrovend
              <br />
              <br />
              BDOK staat voor Bodem Digitaal Op de Kaart en is een online Bodemapplicatie. BDOK
              beschikt over actuele bodemdata uit gevalideerde overheidsbronnen van vrijwel elke
              gemeente, provincie, waterschap en omgevingsdienst. Deze gegevens worden gecombineerd
              met klantspecifieke data, waardoor een unieke landelijke database ontstaat. De
              verzamelde bodemdata wordt met BDOK vertaald naar uiteenlopende toepassingen.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Box
        sx={{
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_2}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '40vh',
          width: '100%',
        }}
      />

      <Container sx={{ py: { lg: 20, md: 15, sm: 10, xs: 5 } }}>
        <Grid container spacing={3}>
          <Grid item order={1} xs={12}>
            <Typography color="primary" sx={{ fontStyle: 'italic' }} variant="h4">
              De BDOK-producten
            </Typography>
          </Grid>
          {/* <Grid item order={2} sx={{ display: 'flex', flexDirection: 'column' }} xs={12}>
            <Typography color="primary" variant="h6">
              Te raadplegen met de applicatie
            </Typography>
          </Grid> */}
          <Grid
            item
            md={6}
            order={{ md: 3, xs: 4 }}
            sx={{ display: 'flex', flexDirection: 'column' }}
            xs={12}
          >
            <Typography color="primary" component="p" variant="h5">
              BDOK bestaat uit verschillende producten, die voorzien in verschillende behoeften. Zo
              heb je een kaart voor calamiteiten die binnen 24 uur behandeld moeten worden en er
              zijn producten die gebruikt kunnen worden bij gepland werk. Hieronder zie je een
              overzicht van onze BDOK-producten:
              <br />
              <br />
              Bij calamiteiten gebruik je de BDOK Storingskaart.
            </Typography>
            <List>
              {['BDOK Storingskaart'].map((title) => (
                <ListItem key={title} disablePadding>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: 'h5' }}>{title}</ListItemText>
                </ListItem>
              ))}
            </List>
            <br />
            <Typography color="primary" variant="h5">
              Bij gepland werk gebruik je de volgende producten:
            </Typography>
            <List>
              {[
                'BDOK Quickscan',
                'BDOK Combirapport',
                'BDOK Huisaansluitingen',
                'BDOK Bulkbevraging',
              ].map((title) => (
                <ListItem key={title} disablePadding>
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ variant: 'h5' }}>{title}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            item
            md={6}
            order={{ md: 4, xs: 3 }}
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            xs={12}
          >
            <div>
              <LocalVideoPlayer
                poster={bdok_quickscan_poster}
                src={bdok_quickscan_video}
                variant="small"
              />
            </div>
            {/* <EmbeddedYoutubeVideo
              placeholder={quickscanPlaceholder}
              src="https://www.youtube-nocookie.com/embed/TQF8oeh7r7g"
              title="BDOK Quickscan"
              variant="small"
            /> */}
          </Grid>

          {/* <Grid item order={5} sx={{ mb: 1 }} xs={12}>
            <Divider />
          </Grid>
          <Grid item order={6} sx={{ display: 'flex', flexDirection: 'column' }} xs={12}>
            <Typography color="primary" variant="h6">
              Te raadplegen buiten de applicatie
            </Typography>
          </Grid> */}

          <Grid item order={6} sx={{ display: 'flex', flexDirection: 'column' }} xs={12}>
            <Typography color="primary" variant="h4">
              Bulkbevraging
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            order={{ md: 7, xs: 8 }}
            sx={{ display: 'flex', flexDirection: 'column' }}
            xs={12}
          >
            <Typography color="primary" variant="h5">
              Wil je juist een groot aantal werklocaties in één keer tegelijk bevragen? Dan is de
              BDOK Bulkbevraging een uitkomst. Daarmee kun je tot duizenden locaties (punten, lijnen
              of complete wijken/vlakken) geautomatiseerd door ons laten verwerken.
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            order={{ md: 8, xs: 7 }}
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
            xs={12}
          >
            <div>
              <LocalVideoPlayer
                poster={bdok_bulkbevraging_poster}
                src={bdok_bulkbevraging_video}
                variant="small"
              />
            </div>
            {/* <EmbeddedYoutubeVideo
            <LocalVideoPlayer src={bdok_bulkbevraging_poster} variant="small" />
            {/* <EmbeddedYoposterVideo
              placeholder={bulkbevragingPlaceholder}
              src="https://www.youtube-nocookie.com/embed/QvAvFfeUzlk"
              title="BDOK Bulkbevraging"
              variant="small"
            /> */}
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          alignItems: 'center',
          backgroundAttachment: isIOS ? undefined : 'fixed',
          backgroundImage: `url('${HERO_IMAGE_3}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          py: { lg: 20, md: 15, sm: 10, xs: 5 },
          width: '100%',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Paper sx={{ minWidth: { sm: '600px', xs: '100%' }, px: 3, py: 5, width: '61.8%' }}>
            <Typography color="secondary" fontStyle="italic" sx={{ pb: 5 }} variant="h4">
              Wil je meer weten over hoe BDOK werkt? Vraag dan hier gratis een demo-presentatie aan!
            </Typography>
            <RequestDemoPresentationFormik>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {(formikProps) => (
                <>
                  <FormikErrorListener
                    onError={() => {
                      enqueueSnackbar('Niet alle velden zijn (correct) ingevuld.', {
                        variant: 'warning',
                      });
                    }}
                  />
                  <RequestDemoPresentationForm {...formikProps} />
                </>
              )}
            </RequestDemoPresentationFormik>
          </Paper>
        </Container>
      </Box>
    </Container>
  );
}
