import {
  ABOUT_BDOK_ROUTE,
  BDOK_ACADEMY_ROUTE,
  NEWS_ROUTE,
  WHAT_IS_BDOK_ROUTE,
} from './routing/ROUTES';
import { NewsView } from './containers/views/news-view';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import AboutBDOKView from './containers/views/AboutBDOKView';
import BDOKAcademyView from './containers/views/BDOKAcademyView';
import CssBaseline from '@mui/material/CssBaseline';
import DefaultLayout from './containers/layouts/DefaultLayout';
import HomeView from './containers/views/HomeView';
import ScrollToTop from './routing/ScrollToTop';
import WhatIsBDOKView from './containers/views/WhatIsBDOKView';
import htmlGlobalStyles from './style/htmlGlobalStyles';
import theme from './style/theme';

function App(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {htmlGlobalStyles}
      <SnackbarProvider maxSnack={3}>
        <ScrollToTop>
          <Routes>
            <Route element={<DefaultLayout />} path="/">
              <Route element={<HomeView />} index />
              <Route element={<AboutBDOKView />} path={ABOUT_BDOK_ROUTE} />
              <Route element={<BDOKAcademyView />} path={BDOK_ACADEMY_ROUTE} />
              <Route element={<WhatIsBDOKView />} path={WHAT_IS_BDOK_ROUTE} />
              <Route element={<NewsView />} path={NEWS_ROUTE} />
              {/* <Route path="*" element={<NoMatch />} /> */}
            </Route>
          </Routes>
        </ScrollToTop>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
