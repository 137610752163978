import { MouseEvent, MouseEventHandler, PropsWithChildren, ReactNode, useState } from 'react';
import { format } from 'date-fns/esm';
import { isUndefined } from 'lodash';
import { nl } from 'date-fns/locale';
import { styled } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText, { DialogContentTextProps } from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography, { TypographyProps } from '@mui/material/Typography';

export function NewsArticleIntroduction({ children }: DialogContentTextProps) {
  return (
    <DialogContentText component="p" paragraph variant="h6">
      {children}
    </DialogContentText>
  );
}

export function NewsArticleDialog({ children, ...props }: DialogProps) {
  const handleClose: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isUndefined(props.onClose)) return;
    props.onClose(e, 'closeButtonClick' as never);
  };

  return (
    <Dialog {...props} fullWidth maxWidth="md">
      {children}
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function NewsArticleContentSubtitle({ children }: PropsWithChildren<{}>) {
  return (
    <DialogTitle component="h2" gutterBottom variant="h5">
      {children}
    </DialogTitle>
  );
}
export function NewsArticleContentText({
  children,
  ...props
}: PropsWithChildren<DialogContentTextProps>) {
  return (
    <DialogContentText paragraph {...props}>
      {children}
    </DialogContentText>
  );
}

interface StyledImageBoxProps {
  src: string;
}

const Img = styled(Box)<StyledImageBoxProps>(({ src }) => ({
  alignItems: 'center',
  backgroundImage: `url('${src}')`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  height: '40vh',
  justifyContent: 'center',
  my: 3,
  width: '100%',
}));

export function NewsArticleContentImage({ src, style, ...props }: StyledImageBoxProps & BoxProps) {
  return <Img src={src} style={{ marginBottom: '16px', ...style }} {...props} />;
}

function Tags({ categories }: Pick<NewsArticleProps, 'categories'>) {
  return (
    <Box alignSelf="start" className="tags" display="flex" gap={2}>
      {categories.map((category) => (
        <Typography
          key={category}
          bgcolor="secondary.main"
          color="white"
          fontWeight={500}
          px={1}
          py={0.5}
          variant="caption"
        >
          {category}
        </Typography>
      ))}
    </Box>
  );
}

export interface NewsArticleProps {
  categories: string[];
  date: Date;
  imageAuthor: string;
  textAuthor: string;
  title: string;
}

export function NewsArticle({
  categories,
  children,
  date,
  imageAuthor,
  textAuthor,
  title,
}: PropsWithChildren<NewsArticleProps>) {
  return (
    <>
      <DialogTitle component="h1" variant="h4">
        {title}
      </DialogTitle>

      <DialogContent>
        <Box
          // alignItems="center"
          display="flex"
          // flexDirection="row"
          justifyContent="space-between"
          sx={{
            alignItems: { md: 'center', xs: 'start' },
            flexDirection: { md: 'row', xs: 'column-reverse' },
            gap: 2,
          }}
          // pt={2}
          // px={3}
        >
          <Typography className="date" fontWeight="500" gutterBottom variant="subtitle2">
            {format(date, 'PPPP', { locale: nl })}
          </Typography>

          <Tags categories={categories} />
        </Box>

        <Typography paragraph variant="button">
          TEKST: {textAuthor} BEELD: {imageAuthor}
        </Typography>

        {children}
      </DialogContent>
    </>
  );
}

NewsArticle.Introduction = NewsArticleIntroduction;
NewsArticle.Subtitle = NewsArticleContentSubtitle;
NewsArticle.Text = NewsArticleContentText;
NewsArticle.Image = NewsArticleContentImage;

export function NewsArticleTile({
  categories,
  date,
  side,
  src,
  title,
  ...props
}: StyledImageBoxProps & NewsArticleProps & BoxProps & { side?: 'left' | 'right' }) {
  return (
    <Box
      sx={{
        backgroundImage: `radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%), url('${src}')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: 1,
        width: 1,
        ...(side === 'left'
          ? {
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingLeft: 'calc(33.3333% / 4)',
              paddingRight: 'calc(33.3333% / 1.5 )',
              paddingTop: '25%',
            }
          : {
              '> .date': {
                textAlign: 'end',
                // border: '3px solid red',
              },
              '> .tags': {
                alignSelf: 'flex-end',
              },
              '> .title': {
                textAlign: 'end',
              },
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              paddingBottom: '25%',
              paddingLeft: 'calc(33.3333% / 1.5 )',
              paddingRight: 'calc(33.3333% / 4)',
            }),
      }}
      {...props}
    >
      <Tags categories={categories} />

      <Typography
        className="date"
        color="white"
        sx={{
          fontWeight: '500',
          textShadow: `0px 0px 3px rgba(0, 0, 0, 0.8), 0px 0px 7px rgba(0, 0, 0, 0.6), 0px 0px 13px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.1)`,
        }}
        variant="h6"
      >
        {format(date, 'PPPP', { locale: nl })}
      </Typography>

      <Typography
        className="title"
        color="white"
        sx={{
          fontWeight: '500',
          textShadow: `0px 0px 3px rgba(0, 0, 0, 0.8), 0px 0px 7px rgba(0, 0, 0, 0.6), 0px 0px 13px rgba(0, 0, 0, 0.3), 0px 0px 20px rgba(0, 0, 0, 0.1)`,
        }}
        variant="h4"
      >
        {title}
      </Typography>
    </Box>
  );
}

export function NewsArticleCommons({
  categories,
  children,
  date,
  imageAuthor,
  side,
  src,
  textAuthor,
  title,
}: NewsArticleProps & { children: ReactNode; side?: 'left' | 'right'; src: string }) {
  const [isArticleOpen, setArticleOpen] = useState(false);

  const openArticle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setArticleOpen(true);
  };

  const closeArticle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setArticleOpen(false);
  };

  return (
    <>
      <NewsArticleTile
        categories={categories}
        date={date}
        imageAuthor={imageAuthor}
        onClick={openArticle}
        side={side}
        src={src}
        textAuthor={textAuthor}
        title={title}
      />

      <NewsArticleDialog onClose={closeArticle} open={isArticleOpen}>
        <NewsArticle
          categories={categories}
          date={date}
          imageAuthor={imageAuthor}
          textAuthor={textAuthor}
          title={title}
        >
          {children}
        </NewsArticle>
      </NewsArticleDialog>
    </>
  );
}
