import { nanoid } from 'nanoid';

import {
  ABOUT_BDOK_ROUTE,
  BDOK_ACADEMY_ROUTE,
  HOME_ROUTE,
  WHAT_IS_BDOK_ROUTE,
} from '../../../routing/ROUTES';
import ABOUT_BDOK_IMAGE from '../../../assets/images/2020-11-16 project Enexis Past. Schiffelerstraat te Landgraaf-21.jpg';
import BDOK_ACADEMY_IMAGE from '../../../assets/images/0051-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import HOME_IMAGE from '../../../assets/images/0043-2021-12-17 Projectshoot Antea Group - TenneT.jpg';
import WHAT_IS_BDOK_IMAGE from '../../../assets/images/0006-2022-03-15 Antea Group-Bergen op Zoom asbestsanering.jpg';

export interface Path {
  id: string;
  image: string;
  title: string;
  to: string;
}

export const PATHS: Path[] = [
  {
    id: nanoid(),
    image: HOME_IMAGE,
    title: 'Home',
    to: HOME_ROUTE,
  },
  {
    id: nanoid(),
    image: ABOUT_BDOK_IMAGE,
    title: 'Over BDOK',
    to: ABOUT_BDOK_ROUTE,
  },
  {
    id: nanoid(),
    image: WHAT_IS_BDOK_IMAGE,
    title: 'Wat is BDOK?',
    to: WHAT_IS_BDOK_ROUTE,
  },
  {
    id: nanoid(),
    image: BDOK_ACADEMY_IMAGE,
    title: 'BDOK Academy!',
    to: BDOK_ACADEMY_ROUTE,
  },
];
