/* eslint-disable @typescript-eslint/no-unused-vars */

import { MouseEvent, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { Link, NavLink, useNavigate } from 'react-router-dom';
import { PATHS } from '../../PATHS';
import AboutUsButtonAndDrawer from './components/AboutUsButtonAndDrawer';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import BDOKLogo from '../../../../../components/BDOKLogo';
import ContactButtonAndDrawer from './components/ContactButtonAndDrawer';
import LinkedinButton from './components/LinkedinButton';
import NavBarItem from './components/NavBarItem';
import PrivacyStatementButton from './components/PrivacyStatementButton';

export default function NavBar() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <AppBar
      color="transparent"
      component="nav"
      elevation={trigger ? 3 : 0}
      sx={{
        backgroundColor: trigger ? `rgba(255,255,255,1)` : `rgba(255,255,255,0)`,
        borderBottom: ({ palette }) =>
          trigger ? `1px solid ${palette.grey[200]}00` : `1px solid ${palette.grey[200]}FF`,
        color: ({ palette }) => (trigger ? palette.primary.main : '#ffffff'),
        top: 0,
        transition: 'all 0.3s ease',
      }}
    >
      <Container maxWidth={false}>
        <Toolbar
          disableGutters
          sx={{
            alignItems: 'unset',
            height: ({ spacing }) => ({ md: spacing(17), xs: spacing(10) }),
          }}
        >
          <Box
            component={BDOKLogo}
            onClick={handleLogoClick}
            singleColor={trigger ? undefined : '#FFFFFF'}
            sx={{
              cursor: 'pointer',
              display: 'block',
              height: '100%',
              mr: 'auto',
            }}
          />

          {/* Links in desktop environment */}
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: ({ spacing }) => spacing(1.75),
                justifyContent: 'flex-end',
                pb: 0,
                pt: 4.75,
              }}
            >
              <Typography
                color={trigger ? 'primary' : 'white'}
                component={Link}
                sx={{
                  '&:hover': {
                    color: ({ palette }) => palette.secondary.main,
                  },
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  display: { md: 'flex', xs: 'none' },
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'color 0.3s ease',
                }}
                to="nieuws"
                variant="body1"
              >
                Nieuws
              </Typography>
              <AboutUsButtonAndDrawer />
              {/* <PrivacyStatementButton /> */}
              <ContactButtonAndDrawer />
              <LinkedinButton />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                gap: ({ spacing }) => spacing(4),
              }}
            >
              {PATHS.map(({ id, title, to }) => (
                <NavBarItem key={id} title={title} to={to} />
              ))}
            </Box>
          </Box>

          {/* Links in responsive environment */}
          <Box
            sx={{
              alignItems: 'center',
              display: { md: 'none', xs: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <IconButton color="inherit" component={Link} size="large" to="nieuws">
              <AnnouncementIcon />
            </IconButton>

            <AboutUsButtonAndDrawer />
            {/* <PrivacyStatementButton /> */}
            <ContactButtonAndDrawer />

            <LinkedinButton />

            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenNavMenu}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              disableScrollLock
              id="menu-appbar"
              keepMounted
              onClose={handleCloseNavMenu}
              open={Boolean(anchorElNav)}
              sx={{
                display: { md: 'none', xs: 'block' },
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              {PATHS.map(({ id, title, to }) => (
                <MenuItem key={id} onClick={handleCloseNavMenu}>
                  <Typography
                    component={NavLink}
                    sx={{
                      '&.active': { color: 'secondary.main' },
                      color: 'primary.main',
                      // fontSize: '2rem',
                      textDecoration: 'none',
                      transition: 'color 0.3s ease',
                    }}
                    textAlign="center"
                    to={to}
                  >
                    {title}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
