import { ReactElement, useState } from 'react';
import { useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AboutCompanyCard } from '../../../../../../components/AboutCompanyCard';
import AnteaGroupLogo from '../../../../../../components/AnteaGroupLogo';
import InfoIcon from '@mui/icons-material/Info';
import NazcaSolutionsLogo from '../../../../../../components/NazcaSolutionsLogo';

export default function AboutUsButtonAndDrawer(): ReactElement {
  // const [state, setState] = useState({
  //   bottom: false,
  //   left: false,
  //   right: false,
  //   top: false,
  // });

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const [state, setState] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  const { breakpoints } = useTheme();
  const mediumScreen = useMediaQuery(breakpoints.up('md'));
  return (
    <>
      <Typography
        color={trigger ? 'primary' : 'white'}
        component="button"
        onClick={toggleDrawer(true)}
        sx={{
          '&:hover': {
            color: ({ palette }) => palette.secondary.main,
          },
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          display: { md: 'flex', xs: 'none' },
          fontWeight: 600,
          textDecoration: 'none',
          transition: 'color 0.3s ease',
        }}
        variant="body1"
      >
        Over ons
      </Typography>

      <IconButton
        onClick={toggleDrawer(true)}
        sx={{ color: trigger ? 'primary.main' : 'white', display: { md: 'none', xs: 'flex' } }}
      >
        <InfoIcon />
      </IconButton>

      <Drawer
        anchor={mediumScreen ? 'right' : 'bottom'}
        disableScrollLock={!mediumScreen}
        // disableScrollLock
        onClose={toggleDrawer(false)}
        open={state}
        PaperProps={{
          sx: ({ spacing }) => ({
            borderRadius: mediumScreen
              ? `${spacing(1)} 0px 0px ${spacing(1)}`
              : `${spacing(1)} ${spacing(1)} 0px 0px `,
            height: mediumScreen ? '100%' : '95%',
            width: mediumScreen ? '38.2%' : '100%',
          }),
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Box
            sx={({ spacing }) => ({
              alignItems: 'center',
              display: 'flex',
              flexShrink: 0,
              height: spacing(8),
              justifyContent: 'flex-end',
              px: spacing(3),
              top: 0,
            })}
          >
            <IconButton
              onClick={toggleDrawer(false)}
              size="small"
              sx={{
                transform: 'translateX(10px)', // Move the icon to the left so the right side of the icon matches the padding instead of the right side of the touchripple
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
          <Box
            sx={({ spacing }) => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              px: spacing(3),
            })}
          >
            <Typography color="secondary" gutterBottom variant="h2">
              Over ons
            </Typography>
            <Grid container sx={{ width: 1 }}>
              <Grid item xs={12}>
                <Typography color="primary" variant="h5">
                  BDOK is een samenwerkingsverband tussen de bedrijven Antea Group en Nazca
                  Solutions. Antea Group houdt zich binnen het samenwerkingsverband bezig met de
                  inhoud, het bodembeleid, de wetgeving, meedenken met wat er speelt in de markt en
                  dit vertalen naar het te ontwikkelen product, waar Nazca Solutions in beeld komt.
                  Nazca Solutions is marktleider op het gebied van bodeminformatiesystemen. Daar
                  komen de krachten samen om tot een prachtig product te komen: BDOK!
                </Typography>
              </Grid>

              <Grid item sx={{ pb: 5, pt: 10 }} xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <AboutCompanyCard
                  body="Antea Group is een internationaal ingenieurs- en adviesbureau; gespecialiseerd in full-service oplossingen op het gebied van milieu, infrastructuur, stedenbouw en water."
                  logo={AnteaGroupLogo}
                  name="Antea Group"
                  sx={{
                    '.root': {
                      flexDirection: { md: 'column-reverse', xs: 'column' },
                    },
                    mb: 5,
                  }}
                  url="https://anteagroup.nl/diensten/milieu-en-omgevingsdata/bodem-digitaal-op-de-kaart"
                />
                <AboutCompanyCard
                  body="Nazca Solutions ontwikkelt innovatieve oplossingen voor het verzamelen, opslaan en ontsluiten van gegevens over zowel de boven- als ondergrond. Nazca Solutions verzorgt en realiseert real-time data die 24/7 online beschikbaar is, voor o.a. Track & Trace oplossingen, Internet-of-Things-toepassingen, bodembeheer, online rapportages en nog veel meer voor een effectief resultaat."
                  logo={NazcaSolutionsLogo}
                  name="Nazca Solutions"
                  sx={{
                    '.root': {
                      flexDirection: { md: 'column-reverse', xs: 'column' },
                    },
                  }}
                  url="https://nazcasolutions.nl/over-nazca/"
                />

                {/* <Box
                  onClick={() => {
                    window.open('https://anteagroup.nl/over-ons');
                  }}
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    my: 5,
                    position: 'relative',
                  }}
                >
                  <Box
                    component={AnteaGroupLogo}
                    sx={{
                      display: 'block',
                      width: '75%',
                    }}
                  />
                  <Typography
                    color="primary"
                    sx={{
                      bottom: 0,
                      position: 'absolute',
                      transform: ({ spacing }) => `translateY(calc(100% + ${spacing(2)}))`,
                      whiteSpace: 'nowrap',
                    }}
                    variant="caption"
                  >
                    Klik op het logo voor meer informatie over Antea Group
                  </Typography>
                </Box>
                <Typography color="secondary" gutterBottom variant="h5">
                  Over Antea Group
                </Typography> */}
              </Grid>
              {/* <Grid
                item
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                xs={12}
              >
                <Typography color="primary" variant="body2">
                  Antea Group is een internationaal ingenieurs- en adviesbureau; gespecialiseerd in
                  full-service oplossingen op het gebied van milieu, infrastructuur, stedenbouw en
                  water.
                </Typography>
              </Grid> */}

              {/* <Grid item sx={{ pb: 2, pt: 5 }} xs={12}>
                <Divider />
              </Grid> */}

              {/* <Grid item xs={12}>
                <Typography color="primary" variant="h5">
                  Over Nazca Solutions
                </Typography>
                <Box
                  onClick={() => {
                    window.open('https://nazcasolutions.nl/over-nazca/');
                  }}
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    my: 5,
                    position: 'relative',
                  }}
                >
                  <Box
                    component={NazcaSolutionsLogo}
                    sx={{
                      display: 'block',
                      width: '75%',
                    }}
                  />
                  <Typography
                    color="primary"
                    sx={{
                      bottom: 0,
                      position: 'absolute',
                      transform: ({ spacing }) => `translateY(calc(100% + ${spacing(2)}))`,
                      whiteSpace: 'nowrap',
                    }}
                    variant="caption"
                  >
                    Klik op het logo voor meer informatie over Nazca Solutions
                  </Typography>
                </Box>
                <Typography color="secondary" gutterBottom variant="h5">
                  Over Nazca Solutions
                </Typography>
              </Grid> */}
              {/* <Grid
                item
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', pb: 5 }}
                xs={12}
              >
                <Typography color="primary" variant="body2">
                  Nazca Solutions ontwikkelt innovatieve oplossingen voor het verzamelen, opslaan en
                  ontsluiten van gegevens over zowel de ondergrond als bovengrond. Nazca Solutions
                  verzorgt en realiseert real-time data die 24/7 online beschikbaar is, voor o.a.
                  Track &amp; Trace oplossingen, Internet-of-Things-toepassingen, bodembeheer,
                  online rapportages en nog veel meer voor een effectief resultaat.
                </Typography>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
